import React, { useEffect, useRef } from 'react';

import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';
import styles from './pVMAF.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { switchVideoFilesSources } from '../../actions/index';

const scores = [
  [
    93.89, 93.31, 93.39, 93.34, 93.04, 92.93, 93.21, 93.44, 95.04, 95.42, 95.47,
    93.92, 94.96, 96.38, 96.95, 97.64, 96.24, 95.98, 96.28, 97.15, 96.23, 95.54,
    96.89, 97.16, 97.71, 96.65, 97.43, 97.61, 97.41, 96.49, 97.41, 96.86, 96.75,
    95.83, 95.82, 97.07, 95.44, 96.72, 95.59, 94.34, 95.96, 96.26, 94.87, 95.61,
    94.47, 93.09, 91.71, 91.41, 91.55, 92.32, 92.41, 90.52, 91.43, 91.31, 93.72,
    94.07, 93.18, 94.39, 95.81, 96.21, 96.69, 96.39, 96.54, 95.68, 95.91, 96.41,
    95.51, 96.36, 96.27, 95.86, 93.86, 94.37, 93.65, 94.58,
  ],
  [
    83.35, 82.82, 82.17, 82.31, 81.57, 83.17, 82.54, 84.11, 83.46, 85.41, 85.11,
    84.02, 85.52, 86.98, 86.77, 87.54, 86.87, 86.79, 86.47, 85.69, 86.22, 86.38,
    87.34, 86.69, 86.15, 87.47, 86.98, 87.55, 86.71, 86.43, 86.29, 87.53, 86.51,
    86.31, 86.49, 86.94, 87.16, 86.32, 85.88, 85.09, 85.38, 86.31, 84.35, 84.78,
    82.81, 82.21, 82.01, 82.08, 81.09, 81.81, 80.65, 81.91, 80.96, 81.37, 82.52,
    83.42, 83.61, 84.03, 84.43, 86.39, 86.65, 87.89, 86.92, 86.43, 86.44, 85.38,
    87.12, 86.79, 86.01, 86.29, 83.75, 83.07, 84.81, 84.11,
  ],
  [
    63.69, 64.11, 63.03, 63.47, 61.85, 63.02, 64.13, 64.91, 63.41, 63.78, 64.94,
    65.61, 65.87, 66.21, 66.14, 67.53, 67.84, 67.09, 65.59, 67.32, 66.45, 66.55,
    66.36, 67.03, 66.22, 67.87, 66.68, 67.25, 67.44, 67.19, 67.93, 67.44, 66.12,
    66.59, 66.91, 66.67, 66.05, 65.56, 64.75, 65.08, 65.47, 64.96, 65.43, 65.41,
    62.94, 62.47, 61.39, 63.04, 61.46, 61.04, 60.63, 61.01, 62.38, 61.89, 63.55,
    64.02, 62.88, 65.31, 64.45, 65.31, 66.61, 67.83, 65.88, 65.58, 65.91, 66.64,
    65.63, 65.22, 66.09, 65.35, 63.57, 62.77, 64.65, 64.74,
  ],
  [
    48.31, 47.81, 47.35, 46.98, 47.61, 47.14, 47.35, 48.99, 49.67, 49.56, 48.87,
    49.23, 50.69, 50.96, 51.68, 52.23, 51.35, 52.42, 51.26, 51.22, 51.02, 50.96,
    50.35, 51.21, 53.05, 52.53, 50.94, 51.26, 52.34, 51.93, 52.49, 50.83, 51.08,
    51.82, 51.66, 52.05, 51.26, 50.83, 51.26, 50.31, 49.82, 49.62, 49.76, 50.08,
    49.02, 48.34, 47.78, 47.24, 48.03, 47.91, 46.21, 46.22, 45.58, 47.01, 48.06,
    48.68, 49.21, 49.28, 49.54, 51.65, 51.33, 52.95, 51.81, 52.16, 51.48, 50.92,
    52.15, 51.01, 51.13, 50.48, 50.07, 48.74, 49.79, 49.21,
  ],
];

const PVMAF = () => {
  const dispatch = useDispatch();

  // Dispatch the action to switch video file sources
  useEffect(() => {
    dispatch(switchVideoFilesSources());
  }, [dispatch]);

  // Select the video file locations from Redux state
  const {
    volley_excellent_95,
    volley_good_85,
    volley_fair_60,
    volley_poor_50,
  } = useSelector((state) => state.videoFilesLocation);

  const video01 = useRef(null);
  const video02 = useRef(null);
  const video03 = useRef(null);
  const video04 = useRef(null);
  const score01Ref = useRef();
  const score02Ref = useRef();
  const score03Ref = useRef();
  const score04Ref = useRef();

  const resetPlayback = () => {
    video01.current.currentTime = 0;
    video02.current.currentTime = 0;
    video03.current.currentTime = 0;
    video04.current.currentTime = 0;
  };

  useEffect(() => {
    var interval;
    if (
      video01.current &&
      video02.current &&
      video03.current &&
      video04.current
    ) {
      interval = setInterval(resetPlayback, 74000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      if (
        score01Ref.current &&
        score02Ref.current &&
        score03Ref.current &&
        score04Ref.current
      ) {
        score01Ref.current.innerText = scores[0][i];
        score01Ref.current.style.color = getScoreColor(scores[0][i]);
        score02Ref.current.innerText = scores[1][i];
        score02Ref.current.style.color = getScoreColor(scores[1][i]);
        score03Ref.current.innerText = scores[2][i];
        score03Ref.current.style.color = getScoreColor(scores[2][i]);
        score04Ref.current.innerText = scores[3][i];
        score04Ref.current.style.color = getScoreColor(scores[3][i]);

        // setScore01(scores[0][i]);
        // setScore02(scores[1][i]);
        // setScore03(scores[2][i]);
        // setScore04(scores[3][i]);
        i = i + 1;
      }
      if (i === scores[0].length) {
        i = 0;
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getScoreColor = (score) => {
    if (score >= 90) return '#44ce1b'; // green
    if (score >= 80) return '#bbdb44'; // lightgreen
    if (score >= 60) return '#f7e379'; // yellow
    if (score >= 40) return '#f2a134'; // orange
    return '#e51f1f'; // red
  };

  return (
    <>
      <style jsx="true" global="false">
        {`
          body,
          html {
            margin: 0;
            padding: 0;
            background-color: black;
            font-family: 'Poppins', sans-serif;
          }
          /* Use a media query to reduce the font size for smaller windows */
          @media (max-width: 600px) {
            .score {
              font-size: 2vw; /* Smaller font size for smaller windows */
            }
          }

          /* For larger windows, maintain a larger font size */
          @media (min-width: 600px) {
            .score {
              font-size: 5vh; /* Larger font size for larger windows */
            }
          }

          .score {
            transition: color 0.5s ease; /* Smooth transition for color change */
          }
        `}
      </style>
      <div className={styles.container}>
        <div className={styles.videocontainer} id="videocontainer">
          <video ref={video01} autoPlay muted loop>
            <source src={volley_excellent_95} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
          <div className={styles.overlaytext}>
            <div
              ref={score01Ref}
              className={styles.score}
              id="score1"
              // style={{ color: getScoreColor(score01) }}
            >
              {/* {score01} */}
            </div>
            <div className={styles.metric}>pVMAF</div>
          </div>
        </div>
        <div className={styles.videocontainer}>
          <video ref={video02} autoPlay muted loop>
            <source src={volley_good_85} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
          <div className={styles.overlaytext}>
            <div
              ref={score02Ref}
              className={styles.score}
              id="score2"
              // style={{ color: getScoreColor(score02) }}
            >
              {/* {score02} */}
            </div>
            <div className={styles.metric}>pVMAF</div>
          </div>
        </div>
        <div className={styles.videocontainer}>
          <video ref={video03} autoPlay muted loop>
            <source src={volley_fair_60} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
          <div className={styles.overlaytext}>
            <div
              ref={score03Ref}
              className={styles.score}
              id="score3"
              // style={{ color: getScoreColor(score03) }}
            >
              {/* {score03} */}
            </div>
            <div className={styles.metric}>pVMAF</div>
          </div>
        </div>
        <div className={styles.videocontainer}>
          <video ref={video04} autoPlay muted loop>
            <source src={volley_poor_50} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
          <div className={styles.overlaytext}>
            <div
              ref={score04Ref}
              className={styles.score}
              id="score4"
              // style={{ color: getScoreColor(score04) }}
            >
              {/* {score04} */}
            </div>
            <div className={styles.metric}>pVMAF</div>
          </div>
        </div>
      </div>
      <NavigationBottomButtons
        homeButtonVisible={false}
        settingsButtonVisible={false}
      />
    </>
  );
};

export default PVMAF;
