import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { switchVideoFilesSources } from '../../actions/index';
import styles from './CloudDVR.module.css';
import logo from '../StartPage/logo.png';
import background from '../StartPage/background.png';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';

const CloudDVR = () => {
  const dispatch = useDispatch();

  // Dispatch the action to switch video file sources
  useEffect(() => {
    dispatch(switchVideoFilesSources());
  }, [dispatch]);

  // Select the video file locations from Redux state
  const { cDVR_Demo_1_Block_1, cDVR_Demo_1_Block_2, cDVR_Demo_1_Block_3, cDVR_Demo_1_Block_4 } = useSelector(
    (state) => state.videoFilesLocation
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [videoSource, setVideoSource] = useState('');

  useEffect(() => {
    if (modalVisible) {
      const videoElement = document.getElementById('modal-video');
      videoElement.load();
      videoElement.play();
    }
  }, [modalVisible, videoSource]);

  // Update the videos object with the new video sources
  const videos = {
    item8: cDVR_Demo_1_Block_1,
    item9: cDVR_Demo_1_Block_2,
    item10: cDVR_Demo_1_Block_3,
    item13: cDVR_Demo_1_Block_4,
  };

  const handleItemClick = (itemKey) => {
    console.log('Video Source URL:', videos[itemKey]);  // Log the video source URL
    setVideoSource(videos[itemKey]);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    const videoElement = document.getElementById('modal-video');
    videoElement.pause();
  };

  const closeModalOnClickOutside = (e) => {
    if (e.target.id === 'modal') {
      closeModal();
    }
  };

  return (
    <div className={styles.cloudDVR}>
      <img className={styles.background} src={background} alt="Background" />
      
      <div className={styles.header}>
        <h1>Common Copy Cloud DVR</h1>
        <img className={styles.logo} src={logo} alt="Logo" />
      </div>  
      <div className={styles.gridContainer}>
        <div className={`${styles.gridItem} ${styles.transparent} ${styles.item1}`}></div>
        <div className={`${styles.gridItem} ${styles.transparent} ${styles.item2}`}></div>
        <div className={`${styles.gridItem} ${styles.item3}`}></div>
        <div className={`${styles.gridItem} ${styles.item4}`}></div>
        <div className={`${styles.gridItem} ${styles.transparent} ${styles.item5}`}></div>
        <div className={`${styles.gridItem} ${styles.transparent} ${styles.item6}`}></div>

        <div className={`${styles.gridItem} ${styles.transparent} ${styles.item7}`}></div>
        <div className={`${styles.gridItem} ${styles.clickable} ${styles.item8}`} onClick={() => handleItemClick('item8')}></div>
        <div className={`${styles.gridItem} ${styles.clickable} ${styles.item9}`} onClick={() => handleItemClick('item9')}></div>
        <div className={`${styles.gridItem} ${styles.clickable} ${styles.item10}`} onClick={() => handleItemClick('item10')}></div>
        <div className={`${styles.gridItem} ${styles.item11}`}></div>
        <div className={`${styles.gridItem} ${styles.transparent} ${styles.item12}`}></div>

        <div className={`${styles.gridItem} ${styles.clickable} ${styles.item13}`} onClick={() => handleItemClick('item13')}></div>
      </div>

      {modalVisible && (
        <div id="modal" className={styles.modal} onClick={closeModalOnClickOutside}>
          <div className={styles.modalContent}>
            <span className={styles.close} onClick={closeModal}>&times;</span>
            <video id="modal-video" autoPlay loop muted>
              <source id="modal-video-source" src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
     <NavigationBottomButtons homeButtonVisible={false} settingsButtonVisible={false} />
    </div>
  );
};

export default CloudDVR;
