import React, { useEffect, useState, useRef } from 'react';
import schedule from 'node-schedule';

import styles from './Dashboard.module.css';
import BarChart from './BarChart';

const Dashboard = ({
  clip_duration,
  break_duration,
  videoPlayer,
  videoSource,
  latency,
  ingestVideoStream,
  noIngestVideoStream,
}) => {
  //first day
  const PROCESSING = [
    0, 151, 302, 453, 604, 755, 906, 1057, 1208, 1359, 1510, 1661, 1812, 1963,
    2114, 2765, 3416, 4067, 4218, 4369, 4520, 4671, 4822, 4973, 5124, 5275,
    5426, 5577, 5728, 5879, 6030, 6181,
  ];
  const DELIVERY = [
    0, 40792, 43512, 51671, 65269, 84306, 111501, 131897, 159092, 197165,
    243396, 293707, 429681, 620044, 756018, 851200, 898791, 949102, 1002132,
    1064680, 1132667, 1197935, 1249605, 1276800, 1317592, 1365183, 1416853,
    1476682, 1528352, 1575943, 1616735, 1657527,
  ];
  const VIEWERS = [
    500, 1000, 3000, 5000, 7000, 10000, 7500, 10000, 14000, 17000, 18500, 50000,
    70000, 50000, 35000, 17500, 18500, 19500, 23000, 25000, 24000, 19000, 10000,
    15000, 17500, 19000, 22000, 19000, 17500, 15000, 15000,
  ];
  // const STORAGE = [
  //   50, 100, 150, 300, 450, 600, 750, 900, 1050, 1200, 1350, 1500, 1650, 1800,
  //   1950, 2100, 2250, 2400, 2550, 2700, 2850, 3000, 3150, 3300, 3450, 3600,
  //   3750, 3900, 4050, 4200, 4350,
  // ];
  // const DELIVERYPERUSER = DELIVERY / VIEWERS[0] / 30;
  const CLIP_DURATION = (clip_duration || 4) * 60;
  const BREAK_DURATION = (break_duration || 2) * 60;
  const CYCLEPERIOD = CLIP_DURATION + BREAK_DURATION;
  const TOTAL_NUMBER_OF_EVENTS_PER_DAY =
    (24 * 3600) / (CLIP_DURATION + BREAK_DURATION);
  const TOTAL_EVENT_SECONDS_PER_DAY =
    TOTAL_NUMBER_OF_EVENTS_PER_DAY * CLIP_DURATION;

  const pricePerHourInput = 0.25;
  const pricePerGBProcessing = 0.39;
  const pricePerGBDelivery = 0.003458;
  //commited numbers
  // const currentDate = new Date();
  // const maxDaysInMonth = new Date(
  //   currentDate.getFullYear(),
  //   currentDate.getMonth() + 1,
  //   0
  // ).getDate();

  const INPUT_COMMITED = 999999999999; //24 * maxDaysInMonth * pricePerHourInput;
  const PROCESSING_COMMITED = 999999999999; //4534 * pricePerGBProcessing;
  const DELIVERY_COMMITED = 999999999999; //1631677 * pricePerGBDelivery;
  // const STORAGE_COMMITED = 4350;
  const PROCESSING_WITH_JITP_1ST = 1 - 58.4 / 100;
  const PROCESSING_WITH_JITP_2ND = 1 - 42.7 / 100;
  const PROCESSING_WITH_JITP_3RD = 1 - 40.1 / 100;
  const PROCESSING_WITH_JITP_4TH = 1 - 36.2 / 100;
  const PROCESSING_WITH_JITP = [
    PROCESSING_WITH_JITP_1ST,
    PROCESSING_WITH_JITP_2ND,
    PROCESSING_WITH_JITP_3RD,
    PROCESSING_WITH_JITP_4TH,
  ];
  // const SAVINGS_WITH_JITP = [
  //   (1 - PROCESSING_WITH_JITP_1ST) * 100,
  //   (1 - PROCESSING_WITH_JITP_2ND) * 100,
  //   (1 - PROCESSING_WITH_JITP_3RD) * 100,
  //   (1 - PROCESSING_WITH_JITP_4TH) * 100,
  // ];
  const randomNumber = () => {
    return Math.random() * (1 - 0.5) + 0.5;
  };

  const DELIVERY_PER_MINUTE = [
    randomNumber(),
    randomNumber(),
    randomNumber(),
    randomNumber(),
    0.01,
  ];
  const [input, setInput] = useState();
  const [processing, setProcessing] = useState();
  const [delivery, setDelivery] = useState();
  const [viewers, setViewers] = useState();
  // const [jitpSavings, setJitpSavings] = useState();
  // const [storage, setStorage] = useState();
  const thumbnailCover = useRef();
  const inputProgress = useRef([0.01, 0.01, 0.01, 0.01, 0.01]);
  const OVERAGECOLORSERIES = 'rgba(21, 91, 20, .5)';
  const inputProgressOverageColorSeries = useRef([
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0)',
  ]);
  let timelineLabelsForBars = useRef([]);
  const processingProgress = useRef([0.01, 0.01, 0.01, 0.01, 0.01]);
  const deliveryProgress = useRef([0.01, 0.01, 0.01, 0.01, 0.01]);
  const processingFactor = useRef(randomNumber());
  const deliveryFactor = useRef(randomNumber());
  const whichMinuteRef = useRef();
  const startShiftingGraphRef = useRef(false);
  const pauseEvent = useRef(false);

  const calculateTimelineLabels = (start, stop) => {
    const now = new Date();
    const timelineLabels = [];
    for (let i = start; i <= stop; i++) {
      let minutes = now.getMinutes() + i;
      let hours = now.getHours();
      if (minutes >= 60) {
        hours++;
        minutes -= 60;
      } else if (minutes < 0) {
        hours--;
        minutes += 60;
      }
      minutes = minutes.toString().padStart(2, '0');
      timelineLabels.push(`${hours}:${minutes}`);
    }
    return timelineLabels;
  };

  const getCurrentPeriodAndMinute = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const NumberOfDaysSinceBegingOfMonth = now.getDate();
    const timeSinceMidnight = hours * 3600 + minutes * 60 + seconds;
    const cycleTimeLeft = CLIP_DURATION - (timeSinceMidnight % CYCLEPERIOD);
    const numberOfPastFullCycles = Math.floor(timeSinceMidnight / CYCLEPERIOD);
    const playEventSecondsSinceMidnight =
      (timeSinceMidnight / (CLIP_DURATION + BREAK_DURATION)) * CLIP_DURATION;
    const ratioPlayEventsSecondsToTotalEventsSecondsPerDay =
      playEventSecondsSinceMidnight / TOTAL_EVENT_SECONDS_PER_DAY;
    const totalPlayTimeSinceStartOfMonth =
      (NumberOfDaysSinceBegingOfMonth - 1) * TOTAL_EVENT_SECONDS_PER_DAY +
      playEventSecondsSinceMidnight;
    let secondOfMinute = 0;

    if (cycleTimeLeft > 0) {
      const whichMinute = Math.floor((CLIP_DURATION - cycleTimeLeft) / 60) + 1;
      secondOfMinute =
        (CLIP_DURATION - cycleTimeLeft - (whichMinute - 1) * 60) / 60;
      return {
        timeSinceMidnight,
        cycleTimeLeft,
        whichMinute,
        secondOfMinute,
        playEventSecondsSinceMidnight,
        ratioPlayEventsSecondsToTotalEventsSecondsPerDay,
        numberOfPastFullCycles,
        NumberOfDaysSinceBegingOfMonth,
        totalPlayTimeSinceStartOfMonth,
      };
    } else {
      const whichMinute = Math.floor(Math.abs(cycleTimeLeft) / 60) + 1;
      return {
        timeSinceMidnight,
        cycleTimeLeft,
        whichMinute,
        secondOfMinute,
        playEventSecondsSinceMidnight,
        ratioPlayEventsSecondsToTotalEventsSecondsPerDay,
        numberOfPastFullCycles,
        NumberOfDaysSinceBegingOfMonth,
        totalPlayTimeSinceStartOfMonth,
      };
    }
  };

  const fillInputValues = (arr, n, value, lastValue) => {
    arr.unshift(lastValue);
    arr.pop();
    for (let i = 0; i < n - 1; i++) {
      arr.unshift(value);
      arr.pop();
    }
    return arr;
  };

  const fillProcessingValues = (arr, n, lastValue) => {
    let newArr = arr.slice(0, n - 1);
    newArr.push(arr[n - 1] * lastValue);
    for (let i = 0; i < arr.length - n; i++) {
      newArr.push(0.01);
    }
    return newArr;
  };

  const fillRandomValues = (arr, n, lastValue) => {
    arr.unshift(lastValue);
    arr.pop();
    for (let i = 0; i < n - 1; i++) {
      arr.unshift(randomNumber());
      arr.pop();
    }
    return arr;
  };

  // const fillInputValuesBreak = (arr, n, value, lastValue) => {
  //   arr.push(lastValue);
  //   arr.shift();
  //   for (let i = 0; i < n - 1; i++) {
  //     arr.push(value);
  //     arr.shift();
  //   }
  //   return arr;
  // };

  // const fillRandomValues = (arr, n) => {
  //   for (let i = 0; i < n; i++) {
  //     arr.unshift(Math.random() * (1 - 0.7) + 0.7);
  //     arr.pop();
  //   }
  //   return arr;
  // };

  const addToEndAndRemoveFirst = (arr, newElement) => {
    const newArray = [...arr];
    newArray.push(newElement);
    newArray.shift();
    return newArray;
  };

  const fillBarGraphOnLoad = (cycleTimeLeft, whichMinute, secondOfMinute) => {
    if (cycleTimeLeft > 0) {
      startShiftingGraphRef.current = false;
      whichMinuteRef.current = whichMinute;
      // indexRef.current = whichMinute - 1;

      inputProgress.current = fillInputValues(
        [0.01, 0.01, 0.01, 0.01, 0.01],
        whichMinute,
        1,
        secondOfMinute
      );
      processingProgress.current = fillProcessingValues(
        [
          PROCESSING_WITH_JITP_1ST,
          PROCESSING_WITH_JITP_2ND,
          PROCESSING_WITH_JITP_3RD,
          PROCESSING_WITH_JITP_4TH,
          0.01,
        ],
        whichMinute,
        secondOfMinute
      );

      inputProgressOverageColorSeries.current = fillInputValues(
        [
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
          'rgba(0, 0, 0, 0)',
        ],
        whichMinute,
        OVERAGECOLORSERIES,
        'rgba(0, 0, 0, 0)'
      );

      deliveryProgress.current = fillRandomValues(
        [0.01, 0.01, 0.01, 0.01, 0.01],
        whichMinute,
        deliveryFactor.current * secondOfMinute
      );

      timelineLabelsForBars.current = calculateTimelineLabels(-4, 4).slice(
        5 - whichMinute,
        10 - whichMinute
      );

      // setJitpSavings(
      //   (SAVINGS_WITH_JITP[whichMinute - 1] * secondOfMinute).toFixed(1)
      // );
    } else {
      const PROCESSING_WITH_JITP_1ST = 1 - 58.4 / 100;
      const PROCESSING_WITH_JITP_2ND = 1 - 42.7 / 100;
      const PROCESSING_WITH_JITP_3RD = 1 - 40.1 / 100;
      const PROCESSING_WITH_JITP_4TH = 1 - 36.2 / 100;

      inputProgress.current = [1, 1, 1, 1, 0.01];
      processingProgress.current = [
        PROCESSING_WITH_JITP_1ST,
        PROCESSING_WITH_JITP_2ND,
        PROCESSING_WITH_JITP_3RD,
        PROCESSING_WITH_JITP_4TH,
        0.01,
      ];
      inputProgressOverageColorSeries.current = [
        OVERAGECOLORSERIES,
        OVERAGECOLORSERIES,
        OVERAGECOLORSERIES,
        OVERAGECOLORSERIES,
        'rgba(0, 0, 0, 0)',
      ];
      deliveryProgress.current = DELIVERY_PER_MINUTE;
      startShiftingGraphRef.current = true;
      whichMinuteRef.current = -1;
      pauseEvent.current = true;

      timelineLabelsForBars.current = calculateTimelineLabels(-4, 0);
      // setJitpSavings();
    }
  };

  const fillBarGraph = (cycleTimeLeft, whichMinute, secondOfMinute) => {
    if (cycleTimeLeft > 0) {
      pauseEvent.current = false;
      if (!startShiftingGraphRef.current) {
        inputProgress.current[whichMinute - 1] = secondOfMinute;
        processingProgress.current[whichMinute - 1] =
          PROCESSING_WITH_JITP[whichMinute - 1] * secondOfMinute;

        if (whichMinute !== 1) {
          inputProgressOverageColorSeries.current = fillInputValues(
            [
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
            ],
            whichMinute - 1,
            OVERAGECOLORSERIES,
            OVERAGECOLORSERIES
          );
        } else
          inputProgressOverageColorSeries.current = [
            'rgba(0, 0, 0, 0)',
            'rgba(0, 0, 0, 0)',
            'rgba(0, 0, 0, 0)',
            'rgba(0, 0, 0, 0)',
            'rgba(0, 0, 0, 0)',
          ];

        deliveryProgress.current[whichMinute - 1] =
          DELIVERY_PER_MINUTE[whichMinute - 1] * secondOfMinute;

        timelineLabelsForBars.current = calculateTimelineLabels(-4, 4).slice(
          5 - whichMinute,
          10 - whichMinute
        );
      } else {
        if (whichMinuteRef.current !== whichMinute) {
          inputProgress.current = addToEndAndRemoveFirst(
            inputProgress.current,
            secondOfMinute
          );
          processingFactor.current = PROCESSING_WITH_JITP[whichMinute - 1];
          processingProgress.current = addToEndAndRemoveFirst(
            processingProgress.current,
            processingFactor.current * secondOfMinute
          );
          // eslint-disable-next-line
          switch (whichMinute) {
            case 1:
              inputProgressOverageColorSeries.current = [
                OVERAGECOLORSERIES,
                OVERAGECOLORSERIES,
                OVERAGECOLORSERIES,
                'rgba(0, 0, 0, 0)',
                'rgba(0, 0, 0, 0)',
              ];
              break;
            case 2:
              inputProgressOverageColorSeries.current = [
                OVERAGECOLORSERIES,
                OVERAGECOLORSERIES,
                'rgba(0, 0, 0, 0)',
                OVERAGECOLORSERIES,
                'rgba(0, 0, 0, 0)',
              ];
              break;
            case 3:
              inputProgressOverageColorSeries.current = [
                OVERAGECOLORSERIES,
                'rgba(0, 0, 0, 0)',
                OVERAGECOLORSERIES,
                OVERAGECOLORSERIES,
                'rgba(0, 0, 0, 0)',
              ];
              break;
            case 4:
              inputProgressOverageColorSeries.current = [
                'rgba(0, 0, 0, 0)',
                OVERAGECOLORSERIES,
                OVERAGECOLORSERIES,
                OVERAGECOLORSERIES,
                'rgba(0, 0, 0, 0)',
              ];
              break;
          }
          deliveryFactor.current = randomNumber();
          deliveryProgress.current = addToEndAndRemoveFirst(
            deliveryProgress.current,
            deliveryFactor.current * secondOfMinute
          );

          timelineLabelsForBars.current = calculateTimelineLabels(-4, 0);

          whichMinuteRef.current = whichMinute;
        } else {
          inputProgress.current[4] = secondOfMinute;
          processingProgress.current[4] =
            processingFactor.current * secondOfMinute;
          deliveryProgress.current[4] = deliveryFactor.current * secondOfMinute;
        }
      }
    } else {
      if (!startShiftingGraphRef.current) {
        inputProgress.current[4] = 0.01;
        processingProgress.current[4] = 0.01;
        deliveryProgress.current[4] = 0.01;
        startShiftingGraphRef.current = true;
        pauseEvent.current = true;
        inputProgressOverageColorSeries.current = [
          OVERAGECOLORSERIES,
          OVERAGECOLORSERIES,
          OVERAGECOLORSERIES,
          OVERAGECOLORSERIES,
          'rgba(0, 0, 0, 0)',
        ];
      } else {
        if (!pauseEvent.current) {
          inputProgress.current = addToEndAndRemoveFirst(
            inputProgress.current,
            0.01
          );
          processingProgress.current = addToEndAndRemoveFirst(
            processingProgress.current,
            0.01
          );
          deliveryProgress.current = addToEndAndRemoveFirst(
            deliveryProgress.current,
            0.01
          );
          whichMinuteRef.current = -1;
          pauseEvent.current = true;

          timelineLabelsForBars.current = calculateTimelineLabels(-4, 0);
        }
      }
    }
  };

  const dataUpdateOnLoad = () => {
    const {
      cycleTimeLeft,
      whichMinute,
      secondOfMinute,
      ratioPlayEventsSecondsToTotalEventsSecondsPerDay,
      NumberOfDaysSinceBegingOfMonth,
      totalPlayTimeSinceStartOfMonth,
    } = getCurrentPeriodAndMinute();

    fillBarGraphOnLoad(cycleTimeLeft, whichMinute, secondOfMinute);

    if (cycleTimeLeft > latency) {
      thumbnailCover.current = undefined;
    } else {
      thumbnailCover.current = styles.blackout;
    }

    if (cycleTimeLeft > 0) {
      setViewers(
        formatNumberWithSpaceSeparator(
          (VIEWERS[NumberOfDaysSinceBegingOfMonth - 1] *
            Math.log10(CLIP_DURATION - cycleTimeLeft + 1)) /
            Math.log10(CLIP_DURATION + 1),
          0
        )
      );
      // thumbnailCover.current = undefined;
    } else {
      setViewers(
        formatNumberWithSpaceSeparator(
          VIEWERS[NumberOfDaysSinceBegingOfMonth - 1] *
            Math.exp((-8 * -cycleTimeLeft) / BREAK_DURATION),
          0
        )
      );
      // thumbnailCover.current = styles.blackout;
    }

    setInput(
      pricePerHourInput * (totalPlayTimeSinceStartOfMonth / 60 / 60).toFixed(1)
    );

    setProcessing(
      pricePerGBProcessing *
        (PROCESSING[NumberOfDaysSinceBegingOfMonth - 1] +
          (PROCESSING[NumberOfDaysSinceBegingOfMonth - 0] -
            PROCESSING[NumberOfDaysSinceBegingOfMonth - 1]) *
            ratioPlayEventsSecondsToTotalEventsSecondsPerDay)
    );

    setDelivery(
      pricePerGBDelivery *
        (DELIVERY[NumberOfDaysSinceBegingOfMonth - 1] +
          (DELIVERY[NumberOfDaysSinceBegingOfMonth - 0] -
            DELIVERY[NumberOfDaysSinceBegingOfMonth - 1]) *
            ratioPlayEventsSecondsToTotalEventsSecondsPerDay)
    );

    // setStorage(
    //   formatNumberWithSpaceSeparator(
    //     STORAGE[NumberOfDaysSinceBegingOfMonth - 1] +
    //       (STORAGE[NumberOfDaysSinceBegingOfMonth] -
    //         STORAGE[NumberOfDaysSinceBegingOfMonth - 1]) *
    //         ratioPlayEventsSecondsToTotalEventsSecondsPerDay,
    //     1
    //   )
    // );
  };

  const dataUpdate = () => {
    //step 1 check where we are in cycle period

    const {
      cycleTimeLeft,
      whichMinute,
      secondOfMinute,
      ratioPlayEventsSecondsToTotalEventsSecondsPerDay,
      NumberOfDaysSinceBegingOfMonth,
      totalPlayTimeSinceStartOfMonth,
    } = getCurrentPeriodAndMinute();

    fillBarGraph(cycleTimeLeft, whichMinute, secondOfMinute);

    if (cycleTimeLeft > 0) {
      // thumbnailCover.current = undefined;
      setInput(
        pricePerHourInput *
          (totalPlayTimeSinceStartOfMonth / 60 / 60).toFixed(1)
      );

      setProcessing(
        pricePerGBProcessing *
          (PROCESSING[NumberOfDaysSinceBegingOfMonth - 1] +
            (PROCESSING[NumberOfDaysSinceBegingOfMonth - 0] -
              PROCESSING[NumberOfDaysSinceBegingOfMonth - 1]) *
              ratioPlayEventsSecondsToTotalEventsSecondsPerDay)
      );

      setDelivery(
        pricePerGBDelivery *
          (DELIVERY[NumberOfDaysSinceBegingOfMonth - 1] +
            (DELIVERY[NumberOfDaysSinceBegingOfMonth - 0] -
              DELIVERY[NumberOfDaysSinceBegingOfMonth - 1]) *
              ratioPlayEventsSecondsToTotalEventsSecondsPerDay)
      );
      // console.log('delivery', NumberOfDaysSinceBegingOfMonth);
      // setStorage(
      //   formatNumberWithSpaceSeparator(
      //     STORAGE[NumberOfDaysSinceBegingOfMonth - 1] +
      //       (STORAGE[NumberOfDaysSinceBegingOfMonth] -
      //         STORAGE[NumberOfDaysSinceBegingOfMonth - 1]) *
      //         ratioPlayEventsSecondsToTotalEventsSecondsPerDay,
      //     1
      //   )
      // );

      setViewers(
        formatNumberWithSpaceSeparator(
          (VIEWERS[NumberOfDaysSinceBegingOfMonth - 1] *
            Math.log10(CLIP_DURATION - cycleTimeLeft + 1)) /
            Math.log10(CLIP_DURATION + 1),
          0
        )
      );
    } else {
      // thumbnailCover.current = styles.blackout;
      setViewers(
        formatNumberWithSpaceSeparator(
          VIEWERS[NumberOfDaysSinceBegingOfMonth - 1] *
            Math.exp((-8 * -cycleTimeLeft) / BREAK_DURATION),
          0
        )
      );
    }
  };

  const formatNumberWithSpaceSeparator = (number, precision) => {
    if (number)
      return number.toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else return null;
  };

  useEffect(() => {
    dataUpdateOnLoad();
    const dataUpdateInterval = setInterval(dataUpdate, 1000);

    //handle ingest video load
    //break period handle
    // eslint-disable-next-line
    const breakJob = schedule.scheduleJob(
      `55 3,8,13,18,23,28,33,38,43,48,53,58 * * * *`,
      () => {
        // thumbnailCover.current = styles.blackout;
        // videoPlayer.current.remove();
        videoSource.current.src = noIngestVideoStream;
        videoPlayer.current.load();
      }
    );
    //play period handle
    // eslint-disable-next-line
    const playJob = schedule.scheduleJob(
      `55 4,9,14,19,24,29,34,39,44,49,54,59 * * * *`,
      () => {
        videoSource.current.src = ingestVideoStream;
        // ingestVideoPlayer.current.currentTime = LATENCY;
        videoPlayer.current.load();
        // thumbnailCover.current = undefined;
      }
    );

    return () => {
      clearInterval(dataUpdateInterval);
      // if (breakJob) breakJob.gracefulShutdown();
      // if (playJob) playJob.gracefulShutdown();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={styles.grid}>
        <div className={`${styles.item} ${styles.panel}`}>Service Status</div>
        <div className={styles.item}>
          <div>Event Ingest</div>
          <div className={styles.playerWindow}>
            <video
              className={styles.player}
              ref={videoPlayer}
              muted
              autoPlay
              loop
            >
              <source ref={videoSource} type="video/mp4"></source>
            </video>
          </div>
        </div>
        <div className={`${styles.item} ${styles.grafanaDescription}`}>
          <div className={styles.gridServiceStatus}>
            <div className={styles.itemAlarmTitle}>Critical Alarms</div>
            <div className={styles.itemAlarmTitle}>Warning Alarms</div>
            <div className={`${styles.alarms} ${styles.greeStatus}`}>0</div>
            <div className={`${styles.alarms} ${styles.greeStatus}`}>0</div>
          </div>
        </div>
        <div className={styles.item}>
          <div>Viewers</div>
          <div className={styles.viewers}>{viewers}</div>
        </div>
        <div className={`${styles.item} ${styles.panel}`}>Current Usage</div>
        <div className={styles.item}>
          <BarChart
            value={inputProgress.current}
            label={'Ingest (minutes)'}
            seriescolor={'rgba(132, 8, 100, .5)'}
            overageColorSeries={[
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
            ]}
            labels={timelineLabelsForBars.current}
          />
        </div>
        <div className={styles.item}>
          <BarChart
            value={processingProgress.current}
            label={'Processing (GB)'}
            seriescolor={'rgba(0, 58, 153, .5)'}
            overageColorSeries={inputProgressOverageColorSeries.current}
            labels={timelineLabelsForBars.current}
          />
        </div>
        <div className={styles.item}>
          <BarChart
            value={deliveryProgress.current}
            label={'Delivery (GB)'}
            seriescolor={'rgba(4, 129, 163, .5)'}
            overageColorSeries={[
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
              'rgba(0, 0, 0, 0)',
            ]}
            labels={timelineLabelsForBars.current}
          />
        </div>
        <div className={`${styles.item} ${styles.legendContainer}`}>
          <div className={styles.legendContainerInfo}>
            <div className={`${styles.box} ${styles.green}`}></div>
            <div>Just-in-Time Savings</div>
          </div>
          <div className={styles.panel} style={{ marginTop: '30px' }}>
            Monthly Bill
          </div>
        </div>
        <div className={styles.item}>
          <div>Ingest ($)</div>
          <div
            className={styles.usageData}
            style={
              parseFloat(input) > parseFloat(INPUT_COMMITED)
                ? { color: '#3380ff' }
                : undefined
            }
          >
            {formatNumberWithSpaceSeparator(input, 1)}
          </div>
          <div className={styles.savingslabel}> </div>
        </div>
        <div className={styles.item}>
          <div>Processing ($)</div>
          <div
            className={styles.usageData}
            style={
              parseFloat(processing) > parseFloat(PROCESSING_COMMITED)
                ? { color: '#3380ff' }
                : undefined
            }
          >
            {formatNumberWithSpaceSeparator(processing, 1)}
          </div>
          <div className={styles.savingslabel}></div>
        </div>
        <div className={styles.item}>
          <div>Delivery ($)</div>
          <div
            className={styles.usageData}
            style={
              parseFloat(delivery) > parseFloat(DELIVERY_COMMITED)
                ? { color: '#3380ff' }
                : undefined
            }
          >
            {formatNumberWithSpaceSeparator(delivery, 1)}
          </div>
          <div className={styles.savingslabel}> </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
