//FrontPage
const synamediaLogo = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia-Logo.mp4',
  local: '/video/Synamedia-Logo.mp4',
};

//FrontPage
const quortexPlay = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Quortex-Play-Demo.mp4',
  local: '/video/Quortex-Play-Demo.mp4',
};

const quortexLink = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Quortex-Link-Demo.mp4',
  local: '/video/Quortex-Link-Demo.mp4',
};

const quortexPlayAds = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Quortex-PlayAds-Demo.mp4',
  local: '/video/Quortex-PlayAds-Demo.mp4',
};

// D2CStreaming - Sports Cockpit
const d2cstreamingvideo = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia-D2CStreaming-SportsEvents.mp4',
  local: '/video/Synamedia-D2CStreaming-SportsEvents.mp4',
};

const d2cstreamingbreak = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia-D2CStreaming-Break.mp4',
  local: '/video/Synamedia-D2CStreaming-Break.mp4',
};

const d2csmallresvideo = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Rugby_216p50.mp4',
  local: '/video/Synamedia_Rugby_216p50.mp4',
};

const d2cnoinputvideo = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/NoInput_216p50.mp4',
  local: '/video/NoInput_216p50.mp4',
};

// pVMAF
const volley_excellent_95 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/volley_excellent_95.mp4',
  local: '/video/volley_excellent_95.mp4',
};

const volley_good_85 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/volley_good_85.mp4',
  local: '/video/volley_good_85.mp4',
};

const volley_fair_60 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/volley_fair_60.mp4',
  local: '/video/volley_fair_60.mp4',
};

const volley_poor_50 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/volley_poor_50.mp4',
  local: '/video/volley_poor_50.mp4',
};

// CostSaving

const costsSavingsFHD = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/CostsSavings/NAB2024_1080p60_avc.mp4',
  local: '/video/NAB2024_1080p60_avc.mp4',
};

const costsSavingsFHDCBR = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/CostsSavings/NAB2024_1080p60_avc.mp4',
  local: '/video/NAB2024_1080p60_avc.mp4',
};

const costsSavingsFHDQCVBR = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/CostsSavings/NAB2024_1080p60_qcvbr.mp4',
  local: '/video/NAB2024_1080p60_qcvbr.mp4',
};
const costsSavingsFHDHEVC = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/CostsSavings/NAB2024_1080p60_hevc.mp4',
  local: '/video/NAB2024_1080p60_hevc.mp4',
};
const costsSavingsFHDVVC = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/CostsSavings/NAB2024_1080p60_hevc_reenc_vvc.mp4',
  local: '/video/NAB2024_1080p60_hevc_reenc_vvc.mp4',
};
const costsSavingsUHD = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/CostsSavings/Vibrant_hevc.mp4',
  local: '/video/Vibrant_hevc.mp4',
};
const costsSavingsUHDVVC = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/CostsSavings/Vibrant_hevc_reenc_vvc.mp4',
  local: '/video/Vibrant_hevc_reenc_vvc.mp4',
};
const costsSavingsUHDAV1 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/CostsSavings/Vibrant_av1.mp4',
  local: '/video/Vibrant_av1.mp4',
};
// AI Subtitles
const rugby720p50ori = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Rugby_720p50_ori.mp4',
  local: '/video/Synamedia_Rugby_720p50_ori.mp4',
};

const rugby720p50subs = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Rugby_720p50_subs.mp4',
  local: '/video/Synamedia_Rugby_720p50_subs.mp4',
};

// cDVR Cost saving

const costsSavingsCDVRnonOptimized_def = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/cDVR_non_optimized.mp4',
  local: '/video/cDVR_non_optimized.mp4',
};

const costsSavingsCDVROptimized_def = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/cDVR_optimized.mp4',
  local: '/video/cDVR_optimized.mp4',
};

const costsSavingsCDVRHowTo_def = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/cDVRHowTo.mp4',
  local: '/video/cDVRHowTo.mp4',
};

// New Sports Movie Clips
const soarKitesurfing = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Soar-Kitesurfing_1080P60Avc.mp4',
  local: '/video/Synamedia_Soar-Kitesurfing_1080P60Avc.mp4',
};

const accelerateHockey = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Accelerate-Hockey_1080P60Avc.mp4',
  local: '/video/Synamedia_Accelerate-Hockey_1080P60Avc.mp4',
};

const impactBoxing = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Impact_Boxing_1080P60Avc.mp4',
  local: '/video/Synamedia_Impact_Boxing_1080P60Avc.mp4',
};

// cDVR Demo Blocks
const cDVR_Demo_1_Block_1 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/cDVR_Demo_1_-_Block_1.mp4',
  local: '/video/cDVR_Demo_1_-_Block_1.mp4',
};

const cDVR_Demo_1_Block_2 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/cDVR_Demo_1_-_Block_2.mp4',
  local: '/video/cDVR_Demo_1_-_Block_2.mp4',
};

const cDVR_Demo_1_Block_3 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/cDVR_Demo_1_-_Block_3.mp4',
  local: '/video/cDVR_Demo_1_-_Block_3.mp4',
};

const cDVR_Demo_1_Block_4 = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/cDVR_Demo_1_-_Block_4.mp4',
  local: '/video/cDVR_Demo_1_-_Block_4.mp4',
};

// cDVR Demo Blocks
const servicesGNOC_HD_Subs = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Services_GNOC_HD_subs.mp4',
  local: '/video/Synamedia_Services_GNOC_HD_subs.mp4',
};

const servicesGNOC_HD_Voice = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Services_GNOC_HD_subs.mp4',
  local: '/video/Synamedia_Services_GNOC_HD_subs.mp4',
};

const servicesGNOC_UHD_Subs = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Services_GNOC_HD_subs.mp4',
  local: '/video/Synamedia_Services_GNOC_HD_subs.mp4',
};

const servicesGNOC_UHD_Voice = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Services_GNOC_HD_subs.mp44',
  local: '/video/Synamedia_Services_GNOC_HD_subs.mp4',
};

const frameRateConversion_Hockey_25fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.25fps.mp4',
  local:
    '/video/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.25fps.mp4',
};
const frameRateConversion_Hockey_2997fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.2997fps.mp4',
  local:
    '/video/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.2997fps.mp4',
};
const frameRateConversion_Hockey_30fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.30fps.mp4',
  local:
    '/video/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.30fps.mp4',
};
const frameRateConversion_Hockey_50fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.50fps.mp4',
  local:
    '/video/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.50fps.mp4',
};
const frameRateConversion_Hockey_5994fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.5994fps.mp4',
  local:
    '/video/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.5994fps.mp4',
};
const frameRateConversion_Hockey_60fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.60fps.mp4',
  local:
    '/video/Synamedia_Accelerate-Hockey_1080p60_SDR_Rec709_AVC-Hi422P_56Mbps.60fps.mp4',
};
const frameRateConversion_Kite_25fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.25fps.mp4',
  local:
    '/video/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.25fps.mp4',
};
const frameRateConversion_Kite_2997fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.2997fps.mp4',
  local:
    '/video/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.2997fps.mp4',
};
const frameRateConversion_Kite_30fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.30fps.mp4',
  local:
    '/video/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.30fps.mp4',
};
const frameRateConversion_Kite_50fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.50fps.mp4',
  local:
    '/video/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.50fps.mp4',
};
const frameRateConversion_Kite_5994fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.5994fps.mp4',
  local:
    '/video/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.5994fps.mp4',
};
const frameRateConversion_Kite_60fps = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.60fps.mp4',
  local:
    '/video/Synamedia_Soar-KiteSurfing_1080p60_SDR_Rec709_56Mbps_AVC-Hi422P.60fps.mp4',
};

const streamlayerClip = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/Streamlayer-Synamedia-IBC2024.mp4',
  local: '/video/Streamlayer-Synamedia-IBC2024.mp4',
};

const hockey1Clip = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/HOCKEY1.mp4',
  local: '/video/HOCKEY1.mp4',
};

const hockey2Clip = {
  remote:
    'https://video-bucket-tradeshow.app.cdn.cloud.synamedia.co.uk/HOCKEY2.mp4',
  local: '/video/HOCKEY2.mp4',
};

export {
  synamediaLogo,
  quortexPlay,
  quortexLink,
  quortexPlayAds,
  d2cstreamingvideo,
  d2cstreamingbreak,
  volley_excellent_95,
  volley_good_85,
  volley_fair_60,
  volley_poor_50,
  d2csmallresvideo,
  d2cnoinputvideo,
  costsSavingsFHD,
  costsSavingsFHDCBR,
  costsSavingsFHDQCVBR,
  costsSavingsFHDHEVC,
  costsSavingsFHDVVC,
  costsSavingsUHD,
  costsSavingsUHDVVC,
  costsSavingsUHDAV1,
  rugby720p50ori,
  rugby720p50subs,
  costsSavingsCDVRnonOptimized_def,
  costsSavingsCDVROptimized_def,
  costsSavingsCDVRHowTo_def,
  soarKitesurfing,
  accelerateHockey,
  impactBoxing,
  cDVR_Demo_1_Block_1,
  cDVR_Demo_1_Block_2,
  cDVR_Demo_1_Block_3,
  cDVR_Demo_1_Block_4,
  servicesGNOC_HD_Subs,
  servicesGNOC_HD_Voice,
  servicesGNOC_UHD_Subs,
  servicesGNOC_UHD_Voice,
  frameRateConversion_Hockey_25fps,
  frameRateConversion_Hockey_2997fps,
  frameRateConversion_Hockey_30fps,
  frameRateConversion_Hockey_50fps,
  frameRateConversion_Hockey_5994fps,
  frameRateConversion_Hockey_60fps,
  frameRateConversion_Kite_25fps,
  frameRateConversion_Kite_2997fps,
  frameRateConversion_Kite_30fps,
  frameRateConversion_Kite_50fps,
  frameRateConversion_Kite_5994fps,
  frameRateConversion_Kite_60fps,
  streamlayerClip,
  hockey1Clip,
  hockey2Clip,
};
