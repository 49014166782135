import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import styles from './SecondPage.module.css';
import background from '../StartPage/background.png';
import logo from '../StartPage/logo.png';
import quortexplay from './img/quortexplaywhite.png';
import quortexlink from './img/quortexlinkwhite.png';
import quortexplaypage from './img/quortexplaypage.png';
import quortexlinkpage from './img/quortexlinkpage.png';
import linkwhite from './img/linkwhite.svg';
import linkblack from './img/linkblack.svg';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';

const SecondPage = () => {
  const navigate = useNavigate();

  const navigateToQuortexLink = () => {
    navigate('/quortexlink');
  };
  const navigateToQuortexPlay = () => {
    navigate('/quortexplay');
  };

  return (
    <div>
      <Helmet>
        <title>QuortexLink</title>
      </Helmet>
      <img className={styles.background} src={background} alt="Logo" />
      <div className={styles.logoContainer}>
        <div></div>
        <img className={styles.logo} src={logo} alt="Logo" />
        <div></div>
      </div>
      <div className={styles.container}>
        <div className={styles.row}>
          <img style={{ height: '12vh' }} src={quortexlink} alt="logo" />
          <p style={{ background: '#3280FF' }}>Distribution</p>
          <h3>
            Define your sources, destinations, and high-availability needs. We
            take care of the rest!
          </h3>
          <div>
            <img
              className={styles.imagePage1}
              style={{ width: '100%' }}
              src={quortexlinkpage}
              alt="logo"
            />
            <div className={styles.imagePage2}>
              <img
                style={{ width: '10vw', opacity: '0.3' }}
                src={linkwhite}
                alt="logo"
                onClick={navigateToQuortexLink}
              />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <img style={{ height: '12vh' }} src={quortexplay} alt="logo" />
          <p style={{ background: '#53D7FB' }}>Streaming</p>
          <h3>
            Simple Streaming, when you need it, <br /> at any scale, any
            workflow!
          </h3>
          <div>
            <img
              className={styles.imagePage1}
              style={{ width: '100%', marginTop: '5px' }}
              src={quortexplaypage}
              alt="logo"
            />
            <div className={styles.imagePage2}>
              <img
                style={{ width: '10vw', opacity: '0.3' }}
                src={linkblack}
                alt="logo"
                onClick={navigateToQuortexPlay}
              />
            </div>
          </div>
        </div>
      </div>
      <NavigationBottomButtons homeButtonVisible={true} settingsButtonVisible={false} />
    </div>
  );
};

export default SecondPage;
