import * as video from '../video';

const switchVideoFilesSources = () => {
  // Get the FQDN or hostname
  const hostName = window.location.hostname;
  var linkList = {};

  var location = 'local';
  if (
    hostName === '127.0.0.1' ||
    hostName === 'tradeshow.app.cdn.cloud.synamedia.co.uk'
  ) {
    location = 'remote';
  } else {
    location = 'local';
  }

  for (const item in video) {
    linkList = { ...linkList, ...{ [item]: video[item][location] } };
  }

  //use remote files
  return {
    type: 'VIDEO_FILE_SOURCES',
    payload: linkList,
  };
};

export { switchVideoFilesSources };
