import React, { useCallback } from 'react';
import { lazy, Suspense } from 'react';

import styles from './LinearADInsertion.module.css';
import Background from '../Background/Background';

const SimpleTheoPlayer = lazy(() =>
  import('../SimpleTheoPlayer/SimpleTheoPlayer')
);

const LinearADInsertion = () => {
  // const startDemo = () => {
  //   fetch(
  //     'https://akrvkuod66fhop5u5zfizd3o4a0cbkdg.lambda-url.us-west-1.on.aws',
  //     {
  //       method: 'POST', // HTTP method
  //       headers: { 'Content-Type': 'application/json' }, // Required for JSON data
  //       body: JSON.stringify({ action: 'power on' }), // Pass data as JSON string
  //     }
  //   );
  // };
  const onPlayer01 = useCallback((player) => {
    player.muted = true;
    player.autoplay = true;
    player.source = {
      sources: [
        {
          src: 'https://d30sfcitgbegmx.cloudfront.net/vdcm-05/dash/WGTV-TV-Region-01/manifest.mpd',
          // src: 'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.mpd',
          type: 'application/dash+xml',
        },
      ],
    };
  }, []);
  const onPlayer02 = useCallback((player) => {
    player.muted = true;
    player.autoplay = true;
    player.source = {
      sources: [
        {
          src: 'https://d30sfcitgbegmx.cloudfront.net/vdcm-05/dash/WGTV-TV-Region-02/manifest.mpd',
          // src: 'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.mpd',
          type: 'application/dash+xml',
        },
      ],
    };
  }, []);
  return (
    <>
      <Background />
      <div className={styles.title}>
        <div className={styles.subtitle01}>Linear AD insertion</div>
      </div>
      <div className={styles.grid}>
        <div className={styles.playerTitle}>Region 01</div>
        <div className={styles.playerTitle}>Region 02</div>
        <Suspense fallback={<div>Loading...</div>}>
          <SimpleTheoPlayer onPlayer={onPlayer01} />
          <SimpleTheoPlayer onPlayer={onPlayer02} />
        </Suspense>
      </div>
      {/* <div className={styles.actionButton}>
        <div className={styles.svgContainer} onClick={startDemo}></div>
      </div> */}
    </>
  );
};

export default LinearADInsertion;
