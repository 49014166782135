import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { Helmet } from 'react-helmet';

import styles from './QuortexPlayLink.module.css';
import background from '../StartPage/background.png';
import logo from '../StartPage/logo.png';
import leftLogo from './img/left.svg';
import rightLogo from './img/right.svg';
import awsLogo from './img/powered-by-aws-white.png';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';

const QuortexPlayLink = ({
  title,
  header,
  sublogo,
  slide,
  video,
  showNavigation,
  sublog_01,
  booth,
}) => {
  const currentSlideNumber = useRef(0);
  const currentSlide = useRef();
  const videoElement = useRef();

  const backward = () => {
    if (currentSlideNumber.current !== 0) {
      currentSlideNumber.current = currentSlideNumber.current - 1;
      currentSlide.current.src = slide[currentSlideNumber.current];
    }
  };

  const forward = () => {
    if (currentSlideNumber.current !== slide.length - 1) {
      currentSlideNumber.current = currentSlideNumber.current + 1;
      currentSlide.current.src = slide[currentSlideNumber.current];
    }
  };

  useEffect(() => {
    if (currentSlide.current) {
      currentSlide.current.src = slide[currentSlideNumber.current];
    }

    if (videoElement.current) {
      videoElement.current.playbackRate = 0.5;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={styles.logoContainer}>
        <img className={styles.background} src={background} alt="Logo" />
        <div className={styles.logoContainer}>
          <div></div>
          <img className={styles.logo} src={logo} alt="Logo" />
          <div></div>
        </div>
      </div>

      <div className={styles.logoContainer_01}>
        <img
          className={styles.logo}
          style={{ width: '20%', margin: '0px' }}
          src={sublogo}
          alt="Logo"
        />
        {sublog_01 ? (
          <img
            className={styles.logo}
            style={{ width: '12%', margin: '0px' }}
            src={sublog_01}
            alt="Logo"
          />
        ) : null}
        {booth ? <p>{booth}</p> : null}
      </div>
      <div className={styles.box}>
        <div className={styles.container}>
          <div className={styles.header}>{header}</div>
          <div className={styles.space}></div>
          <img
            className={styles.slides}
            ref={currentSlide}
            style={{ width: '100%' }}
            alt="Logo"
          />
          <video
            ref={videoElement}
            className={styles.video}
            width="100%"
            height="100%"
            muted
            autoPlay
            loop
            controls
          >
            <source src={video} type="video/mp4"></source>
          </video>

          <div className={styles.control}>
            <div className={styles.slideNavigation}>
              <div
                data-tooltip-id="backward"
                data-tooltip-content="previous slide"
                data-tooltip-place="bottom"
                data-tooltip-delay-show="3000"
                data-tooltip-variant="info"
                className={styles.button}
                onClick={backward}
              >
                <img src={leftLogo} alt="" style={{ width: '60%' }} />
              </div>
              {/* <div
                data-tooltip-id="pause"
                data-tooltip-content="pause auto switching slides"
                data-tooltip-place="bottom"
                data-tooltip-delay-show="3000"
                data-tooltip-variant="info"
                className={styles.button}
                onClick={pause}
              >
                <img src={pauseLogo} alt="" style={{ width: '60%' }} />
              </div> */}
              <div
                data-tooltip-id="forward"
                data-tooltip-content="next slide"
                data-tooltip-place="bottom"
                data-tooltip-delay-show="3000"
                data-tooltip-variant="info"
                className={styles.button}
                onClick={forward}
              >
                <img src={rightLogo} alt="" style={{ width: '60%' }} />
              </div>

              {/* <div
                data-tooltip-id="play"
                data-tooltip-content="auto play slides"
                data-tooltip-place="bottom"
                data-tooltip-delay-show="3000"
                data-tooltip-variant="info"
                className={styles.button}
                onClick={play}
              >
                <img src={playLogo} alt="" style={{ width: '100%' }} />
              </div> */}
              {/* <div
                data-tooltip-id="reset"
                data-tooltip-content="return to first slide"
                data-tooltip-place="bottom"
                data-tooltip-delay-show="3000"
                data-tooltip-variant="info"
                className={styles.button}
                onClick={reset}
              >
                <img src={resetLogo} alt="" style={{ width: '60%' }} />
              </div> */}
            </div>
            <Tooltip id="backward" />
            <Tooltip id="forward" />
            <Tooltip id="pause" content="pause auto switching slides" />
            <Tooltip id="play" />
            <Tooltip id="reset" />
          </div>
          <div className={styles.aws}>
            <img
              className={styles.aws}
              style={{ width: '15%', margin: '0 0 0 0' }}
              src={awsLogo}
              alt="Logo"
            />
          </div>
        </div>
      </div>
      {showNavigation ? <NavigationBottomButtons homeButtonVisible={true} settingsButtonVisible={false} /> : null}
    </div>
  );
};

export default QuortexPlayLink;
