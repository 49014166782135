import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import QuortexPlayLink from './QuortexPlayLink';
import quortexlink from './img/quortexlinkwhite.png';
import slidePage1 from './quortex-link-ppt/page1.png';
import slidePage2 from './quortex-link-ppt/page2.png';
import slidePage3 from './quortex-link-ppt/page3.png';
import { switchVideoFilesSources } from '../../actions/index';

const QuortexLink = () => {
  const dispatch = useDispatch();
  // Dispatch the action to switch video file sources
  useEffect(() => {
    dispatch(switchVideoFilesSources());
  }, [dispatch]);
  const { quortexLink } = useSelector((state) => state.videoFilesLocation);
  const slide = [slidePage1, slidePage2, slidePage3];
  return (
    <div>
      <QuortexPlayLink
        title="Quortex Link"
        header="Cloud Distribution made easy"
        sublogo={quortexlink}
        slide={slide}
        video={quortexLink}
        showNavigation={true}
      />
    </div>
  );
};

export default QuortexLink;
