import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from './Input';
import Button from './Button';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from '../../userpool';

import styles from './Authentication.module.css';

export const authenticate = (
  Username,
  Password,
  setFirstTimeLogin,
  setCognitoUserData
) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: Username,
      Pool: userpool,
    });
    setCognitoUserData(user);
    const authDetails = new AuthenticationDetails({
      Username: Username,
      Password: Password,
    });
    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve({
          result: 'success',
          data: result,
        });
      },
      onFailure: (err) => {
        console.log('login failed', err);
        reject(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified;
        delete userAttributes.phone_number_verified;
        userAttributes.name = authDetails.username;
        resolve({ result: 'passwordChallenge', data: userAttributes });
        setFirstTimeLogin(true);
      },
    });
  });
};

export const newPasswordChallenge = (
  newpassword,
  userAttributes,
  cognitoUserData
) => {
  return new Promise((resolve, reject) => {
    cognitoUserData.completeNewPasswordChallenge(newpassword, userAttributes, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        console.log('changing password failed', err);
        reject(err);
      },
    });
  });
};

const Login = () => {
  const [firstTimeLogin, setFirstTimeLogin] = useState(false);
  const Navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [userAttributesData, setUserAttributesData] = useState();
  const [cognitoUserData, setCognitoUserData] = useState();
  const errorRef = useRef();

  const getUsername = (event) => {
    setUsername(event.target.value);
  };
  const getPassword = (event) => {
    setPassword(event.target.value);
  };
  const getNewPassword = (event) => {
    setNewPassword(event.target.value);
  };

  const handleAuthentication = (
    usename,
    password,
    setFirstTimeLogin,
    setCognitoUserData,
    cognitoUserData
  ) => {
    if (!firstTimeLogin) {
      authenticate(usename, password, setFirstTimeLogin, setCognitoUserData)
        .then((response) => {
          if (response.result === 'success') {
            Navigate('/');
          }
          if (response.result === 'passwordChallenge') {
            setUserAttributesData(response.data);
          }
        })
        .catch((err) => {
          errorRef.current.textContent = err;
        });
    } else {
      newPasswordChallenge(newpassword, userAttributesData, cognitoUserData)
        .then((response) => {
          Navigate('/');
        })
        .catch((err) => {
          errorRef.current.textContent = err;
        });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.label}>Username</div>
          <Input
            placeholder="username"
            value={username}
            setValue={getUsername}
          />

          {firstTimeLogin ? (
            <>
              <div className={styles.label}>Old Password</div>
              <Input
                placeholder="password"
                value={password}
                setValue={getPassword}
                type="password"
              />
              <div className={styles.label}>New Password</div>
              <Input
                placeholder="new password"
                value={newpassword}
                setValue={getNewPassword}
                type="password"
              />
            </>
          ) : (
            <>
              <div className={styles.label}>Password</div>
              <Input
                placeholder="password"
                value={password}
                setValue={getPassword}
                type="password"
              />
            </>
          )}
          <Button
            text="Login"
            onClick={() => {
              handleAuthentication(
                username,
                password,
                setFirstTimeLogin,
                setCognitoUserData,
                cognitoUserData
              );
            }}
          />
        </div>
        <div className={styles.error} ref={errorRef}></div>
      </div>
    </>
  );
};

export default Login;
