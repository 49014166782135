import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import QuortexPlayLink from './QuortexPlayLink';
import quortexplay from './img/quortexplaywhite.png';
import slidePage1 from './quortex-play-ppt/page1.png';
import slidePage2 from './quortex-play-ppt/page2.png';
import slidePage3 from './quortex-play-ppt/page3.png';
import { switchVideoFilesSources } from '../../actions/index';

const QuortexPlay = () => {
  const dispatch = useDispatch();
  // Dispatch the action to switch video file sources
  useEffect(() => {
    dispatch(switchVideoFilesSources());
  }, [dispatch]);
  const { quortexPlay } = useSelector((state) => state.videoFilesLocation);
  const slide = [slidePage1, slidePage2, slidePage3];
  return (
    <QuortexPlayLink
      title="Quortex Play"
      header="Just-in-Time Cloud Streaming at Scale"
      sublogo={quortexplay}
      slide={slide}
      video={quortexPlay}
      showNavigation={true}
    />
  );
};

export default QuortexPlay;
