import styles from './Background.module.css';

import background from '../StartPage/background.png';
import logo from '../StartPage/logo.png';

const Background = () => {
  return (
    <>
      <img className={styles.background} src={background} alt="Logo" />
      <div className={styles.logoContainer}>
        <div></div>
        <img className={styles.logo} src={logo} alt="Logo" />
        <div></div>
      </div>
    </>
  );
};

export default Background;
