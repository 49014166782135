import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';

import styles from './Instructions.module.css';
import windowsIcon from './img/win.svg'; // Assume you have this icon
import macIcon from './img/mac.svg';     // Assume you have this icon

const Settings = () => {
  return (
    <>
      <Helmet>
        <title>Settings - Choose Installation Instructions</title>
      </Helmet>
      <div className={styles.container}>
        <h1>Settings</h1>
        <h2>Offline Installation</h2>
        <div className={styles.offlineInstallation}>
          <Link to="../instructions-windows" className={styles.option}>
            <div className={styles.iconContainer}>
              <img src={windowsIcon} alt="Windows" className={styles.icon} />
            </div>
            <span>Windows</span>
          </Link>
          <Link to="../instructions-mac" className={styles.option}>
            <div className={styles.iconContainer}>
              <img src={macIcon} alt="Mac" className={styles.icon} />
            </div>
            <span>Mac</span>
          </Link>
        </div>
        <NavigationBottomButtons homeButtonVisible={false} settingsButtonVisible={false} />
      </div>
    </>
  );
};

export default Settings;
