import { useEffect, useCallback, useState } from 'react';
import '../../../node_modules/@theoplayer/basic-hls-dash/ui.css';
import { Player } from '@theoplayer/basic-hls-dash';

import { libraryLocation, license } from '../../theoplayerLicense';

const SimpleTheoPlayer = ({ onPlayer }) => {
  const [player, setPlayer] = useState();
  const onMount = useCallback(
    (node) => {
      if (node) {
        const config = {
          license,
          libraryLocation,
          ui: {
            fluid: true,
          },
        };

        setPlayer(new Player(node, config));
      }
    },
    // eslint-disable-next-line
    [license]
  );

  useEffect(() => {
    if (player && onPlayer) {
      onPlayer(player);
    }
  }, [player, onPlayer]);

  return (
    <div
      className="theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"
      // style={{ height: '100%' }}
      ref={onMount}
    />
  );
};

export default SimpleTheoPlayer;
