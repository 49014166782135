import React from 'react';

import styles from './Template.module.css';

const Template = () => {
  return (
    <>
      <div className={styles.template}>Temlate Web Page</div>
      <div className={styles.template}>Temlate Web Page</div>
      <div className={styles.template}>Temlate Web Page</div>
      <div className={styles.template}>Temlate Web Page</div>
    </>
  );
};

export default Template;
