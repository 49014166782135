import React, { useCallback, useMemo, lazy, Suspense, useRef } from 'react';
import { Helmet } from 'react-helmet';

import styles from './SideBySide.module.css';

const SimpleTheoPlayer = lazy(() =>
  import('../SimpleTheoPlayer/SimpleTheoPlayer')
);

const SideBySide = () => {
  const src1 = {
    src: 'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.m3u8',
    type: 'application/x-mpegurl',
    lowLatency: true,
  };

  const src2 = {
    src: 'https://vn-public-atl-dmz.demo.synamedia.co.uk/content/cmaf/low-latency-rugby/manifest.m3u8',
    type: 'application/x-mpegurl',
    lowLatency: true,
  };

  return (
    <>
      <Helmet>
        <title>Side By Side</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header01}>Title 01</div>
        <div className={styles.header02}>Title 02</div>
        <div className={styles.video01}>
          <RenderPlayerWindow src={src1} />
        </div>
        <div className={styles.video02}>
          <RenderPlayerWindow src={src2} />
        </div>
        <div className={styles.clock01}></div>
        <div className={styles.clock02}></div>
        <div className={styles.info01}></div>
        <div className={styles.info02}></div>
      </div>
    </>
  );
};

const RenderPlayerWindow = ({ src }) => {
  const latencyInfoRef = useRef();
  const onPlayer = useCallback((player) => {
    player.preload = 'auto';
    player.abr.strategy = 'quality';
    player.controls = true;
    player.ui.loadingSpinner.hide();
    player.muted = true;
    player.autoplay = true;
    player.source = {
      sources: [src],
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className={styles.player}>
        <Suspense fallback={<div>Loading...</div>}>
          <SimpleTheoPlayer onPlayer={onPlayer} />
        </Suspense>
      </div>
    </>
  );
};

export default SideBySide;
