import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './FrontPage.module.css';
import background from '../StartPage/background.png';
import Logout from '../Authentication/Logout';
import userpool from '../../userpool';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';


const FrontPage = () => {
  const [isSynamediaUser, setIsSynamediaUser] = useState(false);

  useEffect(() => {
    const user = userpool.getCurrentUser();
    if (user && user.username === 'synamedia') {
      setIsSynamediaUser(true);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Synamedia Demo List</title>
      </Helmet>
      <Logout />
      <img
        className={styles.background}
        src={background}
        alt="Synamedia Background"
        loading="lazy"
      />
      <h1 className={styles.header}>Demo Catalogue</h1>

      <div className={styles.container}>
        {/* First Row of Categories */}
        <div className={styles.row}>
          {/* B2B Distribution */}
          <div className={styles.category}>
            <h2>B2B Distribution</h2>
            <ul className={styles.linkList}>
              <li>
                <Link className={styles.link} to="/powervu">Quortex PowerVU</Link>
              </li>
              <li>
                <Link className={styles.link} to="/RegionalInsertion">Regional Graphics Insertion</Link>
              </li>
              <li>
                <Link className={styles.link} to="/frc">Framerate Conversion</Link>
              </li>
              <li>
                <Link className={styles.link} to="/AISubtitles">AI-Based Caption Generation</Link>
              </li>
            </ul>
          </div>

          {/* D2C Streaming */}
          <div className={styles.category}>
            <h2>D2C Streaming</h2>
            <ul className={styles.linkList}>
              <li>
                <a
                  className={styles.link}
                  href="https://app.exp.nativewaves.com/v/4/configs/showcase/manifests/gvgl8xaq4o9x5e70"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Connecting Fans
                </a>
              </li>
              <li>
                <Link className={styles.link} to="/qpad">Sports Streaming</Link>
              </li>
            </ul>
          </div>

          {/* Ad Insertion */}
          <div className={styles.category}>
            <h2>Ad Insertion</h2>
            <ul className={styles.linkList}>
              <li>
                <Link className={styles.link} to="/quortexplayads">Personalized Ad Insertion</Link>
              </li>
              <li>
                <Link className={styles.link} to="/ShoppableAds">ShoppableAds</Link>
              </li>
            </ul>
          </div>

          {/* CloudDVR */}
          <div className={styles.category}>
            <h2>CloudDVR</h2>
            <ul className={styles.linkList}>
              <li>
                <Link className={styles.link} to="/clouddvr">Common Copy Cloud DVR</Link>
              </li>
              <li>
                <Link className={styles.link} to="/cDVR">Cloud DVR Cost Savings</Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Second Row of Categories */}
        <div className={styles.row}>
          {/* Compression */}
          <div className={styles.category}>
            <h2>Compression</h2>
            <ul className={styles.linkList}>
              <li>
                <Link className={styles.link} to="/costsavings">Compression Cost Savings</Link>
              </li>
              <li>
                <Link className={styles.link} to="/pVMAF">pVMAF</Link>
              </li>
            </ul>
          </div>

          {/* Innovation */}
          <div className={styles.category}>
            <h2>Innovation</h2>
            <ul className={styles.linkList}>
              <li>
                <Link className={styles.link} to="/inno">Compression Innovation</Link>
              </li>
              <li>
                <Link className={styles.link} to="/probe">Live VQ Probe</Link>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div className={styles.category}>
            <h2>Services</h2>
            <ul className={styles.linkList}>
              <li>
                <Link className={styles.link} to="/Services">Synamedia Services</Link>
              </li>
            </ul>
          </div>

          {/* General */}
          <div className={styles.category}>
            <h2>General</h2>
            <ul className={styles.linkList}>
              <li>
                <Link className={styles.link} to="/quortex">Quortex Link & Play</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <NavigationBottomButtons
        homeButtonVisible={false}
        settingsButtonVisible={isSynamediaUser} // Conditionally show based on user
      />
    </div>
  );
};

export default FrontPage;
