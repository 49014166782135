import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import background from '../StartPage/background.png';
import schedule from 'node-schedule';

import styles from './QuortexPlaySchedular.module.css';
import Dashboard from './Dashboard';
// import moment from 'moment-timezone';
import Schedule from './Schedule';
import quortexLogo from './images/Quortex-Logo-Colour-Dark-RGB.svg';
import logo from '../StartPage/logo.png';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';
import highlight01 from './images/highlight01.jpg';
import highlight02 from './images/highlight02.jpg';
import highlight03 from './images/highlight03.jpg';
import highlight04 from './images/highlight04.jpg';
import highlight05 from './images/highlight05.jpg';
import highlight06 from './images/highlight06.jpg';
import irisLogo from './images/Iris-Logo-Colour-Dark-RGB.svg';
// import googleAdManagerLogo from './images/logo_admanager_192px_MuAH4Zs.max-800x800.png';
import googleAdManagerLogo2 from './images/Google_Ad_Manager.png';
import castlabLogo from './images/castlabs-logo.svg';
import ezdrmLogo from './images/ezdrm-logo-variation.svg';
import M2MediaLogo from './images/M2AMediaLogo_Site_RGB_WEB-1.png';
import bitmoving from './images/bitmovin.svg';
import twoCodersLogo from './images/2coders-logo-blue-1.svg';
import {
  d2cstreamingvideo,
  d2cstreamingbreak,
  d2csmallresvideo,
  d2cnoinputvideo,
} from '../../video';

const LATENCY = 5.186;

const QuortexPlaySchedular = () => {
  var videoFile = '';
  var pauseVideoFile = '';
  var ingestVideoStream = '';
  var noIngestVideoStream = '';
  const hostName = window.location.hostname;
  if (
    hostName === '127.0.0.1' ||
    hostName === 'tradeshow.app.cdn.cloud.synamedia.co.uk'
  ) {
    videoFile = d2cstreamingvideo.remote;
    pauseVideoFile = d2cstreamingbreak.remote;
    ingestVideoStream = d2csmallresvideo.remote;
    noIngestVideoStream = d2cnoinputvideo.remote;
  } else {
    videoFile = d2cstreamingvideo.local;
    pauseVideoFile = d2cstreamingbreak.local;
    ingestVideoStream = d2csmallresvideo.local;
    noIngestVideoStream = d2cnoinputvideo.local;
  }
  const ingestVideoPlayer = useRef();
  const ingestVideoSource = useRef();
  const [switchD2C, setSwitchD2C] = useState('#0481A3');
  const [switchAD, setSwitchAD] = useState('');

  const CLIP_DURATION = 4; //minutes
  const BREAK_DURATION = 1; //minutes
  const CYCLEPERIOD = (CLIP_DURATION + BREAK_DURATION) * 60;
  const video = useRef();
  const source = useRef();
  // const videoClockRef = useRef();
  // const wallclockRef = useRef();
  const eventName = useRef();
  const [highlightVisibility01, setHighlightVisibility01] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility02, setHighlightVisibility02] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility03, setHighlightVisibility03] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility04, setHighlightVisibility04] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility05, setHighlightVisibility05] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility06, setHighlightVisibility06] = useState({
    visibility: 'hidden',
  });

  const setD2C = () => {
    if (switchD2C !== '#0481A3') {
      setSwitchD2C('#0481A3'); // Active tab color
      setSwitchAD('black'); // Inactive tab color
    }
  };

  const setAD = () => {
    if (switchAD !== '#0481A3') {
      setSwitchD2C('black'); // Inactive tab color
      setSwitchAD('#0481A3'); // Active tab color
    }
  };

  const highlights = [
    {
      time: 20,
      setVisibility: setHighlightVisibility01,
      interval: undefined,
    },
    {
      time: 40,
      setVisibility: setHighlightVisibility02,
      interval: undefined,
    },
    {
      time: 80,
      setVisibility: setHighlightVisibility03,
      interval: undefined,
    },
    {
      time: 100,
      setVisibility: setHighlightVisibility04,
      interval: undefined,
    },
    {
      time: 140,
      setVisibility: setHighlightVisibility05,
      interval: undefined,
    },
    {
      time: 160,
      setVisibility: setHighlightVisibility06,
      interval: undefined,
    },
  ];

  window.qpDemoPlayer = video.current;

  // const resizeWindow = () => {
  //   setWindowWidth(window.innerWidth);
  // };

  //track window resize
  // useEffect(() => {
  //   resizeWindow();
  //   window.addEventListener('resize', resizeWindow);
  //   return () => window.removeEventListener('resize', resizeWindow);
  // }, []);

  // const channels = useMemo(
  //   () => [
  //     {
  //       logo: synamedia,
  //       uuid: channelUIID,
  //     },
  //   ],
  //   []
  // );

  // const delay = (ms) => {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, ms);
  //   });
  // };
  var playVideoTimeout01 = undefined;
  const playVideo = () => {
    playVideoTimeout01 = setTimeout(() => {
      source.current.src = pauseVideoFile;

      //remove highlights
      highlights.forEach((e) => {
        e.setVisibility({
          visibility: 'hidden',
        });
      });

      eventName.current.textContent = 'Break';
      video.current.load();
    }, CLIP_DURATION * 60 * 1000);
    //main screen
    source.current.src = videoFile;
    eventName.current.textContent = 'On Air';
    video.current.load();
    //ingest
    // ingestVideoSource.current.src = ingestVideoStream;
    // ingestVideoPlayer.current.currentTime = LATENCY;
    // ingestVideoPlayer.current.load();
  };
  //player
  useEffect(() => {
    var playVideoTimeout02 = undefined;
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const timeSinceMidnight = hours * 60 * 60 + minutes * 60 + seconds;
    const cycleTimeLeft =
      CLIP_DURATION * 60 - (timeSinceMidnight % CYCLEPERIOD);
    if (cycleTimeLeft > 0) {
      source.current.src = videoFile;
      ingestVideoSource.current.src = ingestVideoStream;
      eventName.current.textContent = 'On Air';
      video.current.currentTime = CLIP_DURATION * 60 - cycleTimeLeft;
      ingestVideoPlayer.current.currentTime =
        CLIP_DURATION * 60 - cycleTimeLeft + LATENCY;
      ingestVideoPlayer.current.load();

      playVideoTimeout02 = setTimeout(() => {
        source.current.src = pauseVideoFile;
        //remove highlights
        highlights.forEach((e) => {
          e.setVisibility({
            visibility: 'hidden',
          });
        });
        eventName.current.textContent = 'Break';
        video.current.load();
      }, cycleTimeLeft * 1000);
    } else {
      source.current.src = pauseVideoFile;
      //remove highlights
      highlights.forEach((e) => {
        e.setVisibility({
          visibility: 'hidden',
        });
      });
      video.current.load();
      eventName.current.textContent = 'Break';
      video.current.currentTime = -cycleTimeLeft;
      ingestVideoSource.current.src = noIngestVideoStream;
      ingestVideoPlayer.current.load();
    }
    // eslint-disable-next-line
    const job = schedule.scheduleJob(
      `*/${CLIP_DURATION + BREAK_DURATION} * * * *`,
      playVideo
    );
    return () => {
      if (playVideoTimeout01) {
        clearTimeout(playVideoTimeout01);
      }
      if (playVideoTimeout02) {
        clearTimeout(playVideoTimeout02);
      }
    };
    // eslint-disable-next-line
  }, []);

  //wallclock
  // useEffect(() => {
  //   if (video.current) {
  //     // var wallclockUpdate = setInterval(() => {
  //     //   const now = moment();
  //     //   if (!video.current.paused && source.current.src !== pauseVideoFile) {
  //     //     wallclockRef.current.textContent = `${('00' + now.hour()).slice(
  //     //       -2
  //     //     )}:${('00' + now.minutes()).slice(-2)}:${('00' + now.seconds()).slice(
  //     //       -2
  //     //     )}:${('000' + now.millisecond()).slice(-3)}`;
  //     //     now.subtract(LATENCY, 'seconds');
  //     //     videoClockRef.current.textContent = `${('00' + now.hour()).slice(
  //     //       -2
  //     //     )}:${('00' + now.minutes()).slice(-2)}:${('00' + now.seconds()).slice(
  //     //       -2
  //     //     )}:${('000' + now.millisecond()).slice(-3)}`;
  //     //   }
  //     //   if (source.current.src === pauseVideoFile) {
  //     //     wallclockRef.current.textContent = null;
  //     //     videoClockRef.current.textContent = null;
  //     //   }
  //     // }, 1);
  //   }

  //   return () => {
  //     // clearInterval(wallclockUpdate);
  //   };
  //   // eslint-disable-next-line
  // }, []);

  //highlights
  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const timeSinceMidnight = hours * 60 * 60 + minutes * 60 + seconds;
    const cycleTimeLeft =
      CLIP_DURATION * 60 - (timeSinceMidnight % CYCLEPERIOD);
    if (cycleTimeLeft > 0) {
      highlights.forEach((e) => {
        if (e.time < CLIP_DURATION * 60 - cycleTimeLeft) {
          e.setVisibility({
            visibility: 'visible',
          });
        } else {
          e.interval = setTimeout(() => {
            e.setVisibility({
              visibility: 'visible',
            });
          }, (e.time - CLIP_DURATION * 60 + cycleTimeLeft) * 1000);
        }
      });
    }
    const showHighlights = () => {
      highlights.forEach((e) => {
        e.interval = setTimeout(() => {
          e.setVisibility({
            visibility: 'visible',
          });
        }, e.time * 1000);
      });
    };
    // eslint-disable-next-line
    const job = schedule.scheduleJob(
      `*/${CLIP_DURATION + BREAK_DURATION} * * * *`,
      showHighlights
    );
    return () => {
      highlights.forEach((e) => {
        if (e.interval) clearTimeout(e.interval);
      });
      // if (job) job.gracefulShutdown();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Quortex Play</title>
      </Helmet>

      <div className={styles.screenCenter}>
        <img className={styles.background} src={background} alt="Background" />
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {switchD2C === '#0481A3' ? (
              <div className={styles.title_1}>Sports Streaming Cockpit</div>
            ) : (
              <div className={styles.title_1}>
                Monetize Sports Streaming At Scale
              </div>
            )}
          </div>
          <img className={styles.logo} src={quortexLogo} alt="Right Logo" />
        </div>
        <div className={styles.container}>
          <div className={styles.leftSide}>
            <div className={styles.switchButtons}>
              <div style={{ backgroundColor: switchD2C }} onClick={setD2C}>
                Streaming
              </div>
              <div style={{ backgroundColor: switchAD }} onClick={setAD}>
                DAI
              </div>
            </div>
            <div className={styles.dashboardContainer}>
              <Dashboard
                clip_duration={CLIP_DURATION}
                break_duration={BREAK_DURATION}
                videoPlayer={ingestVideoPlayer}
                videoSource={ingestVideoSource}
                ingestVideoStream={ingestVideoStream}
                noIngestVideoStream={noIngestVideoStream}
                latency={LATENCY}
                switchD2C={switchD2C}
              />
            </div>
            <div style={{ padding: '0 30px 0 30px' }}>
              <Schedule
                clip_duration={CLIP_DURATION}
                break_duration={BREAK_DURATION}
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.center}>
              <div className={styles.playerContainer}>
                <div className={styles.playerHeader} ref={eventName}></div>
                <div className={styles.player}>
                  <video
                    className={styles.playerWindow}
                    ref={video}
                    muted
                    autoPlay
                    loop
                    // controls
                  >
                    <source
                      ref={source}
                      src={videoFile}
                      type="video/mp4"
                    ></source>
                  </video>

                  {/* <div className={styles.wallclock} ref={wallclockRef}></div>
                  <div className={styles.videoClock} ref={videoClockRef}></div> */}
                </div>
                {/* <img className={styles.adImage} src={ad01} alt="Logo" /> */}
                {/* <div className={styles.cockpit}>
                    Synamedia Streaming Cockpit
                  </div> */}
              </div>
              <div className={styles.infoPanel}>
                <div>Powered by vDCM</div>
                <div>pVMAF=90</div>
                <div>Latency=6s</div>
              </div>
              <div className={`${styles.item} ${styles.panel}`}>
                {/* Content for the first row */}Highlights
              </div>
              <div className={styles.highlights}>
                {/* Repeat the following img tag for up to five images */}
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility01}
                  src={highlight01}
                  alt="highlight01"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility02}
                  src={highlight02}
                  alt="highlight02"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility03}
                  src={highlight03}
                  alt="highlight03"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility04}
                  src={highlight04}
                  alt="highlight04"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility05}
                  src={highlight05}
                  alt="highlight05"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility06}
                  src={highlight06}
                  alt="highlight06"
                />
              </div>
              <div className={styles.vendorLogos}>
                {
                  switchD2C === '#0481A3' ? (
                    <>
                      <div style={{ width: '400px' }}>
                        <img
                          style={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                          }}
                          src={castlabLogo}
                          alt="Logo"
                        />
                      </div>
                      <div style={{ width: '400px' }}>
                        <img
                          style={{
                            display: 'block',
                            width: '70%',
                            height: 'auto',
                          }}
                          src={ezdrmLogo}
                          alt="Logo"
                        />
                      </div>
                      <div style={{ width: '500px' }}>
                        <img
                          style={{
                            display: 'block',
                            width: '90%',
                            height: 'auto',
                          }}
                          src={M2MediaLogo}
                          alt="Logo"
                        />
                      </div>
                      <div style={{ width: '400px' }}>
                        <img
                          style={{
                            display: 'block',
                            width: '100%',
                            height: 'auto',
                          }}
                          src={bitmoving}
                          alt="Logo"
                        />
                      </div>
                      <div style={{ width: '400px' }}>
                        <img
                          style={{
                            display: 'block',
                            width: '90%',
                            height: 'auto',
                          }}
                          src={twoCodersLogo}
                          alt="Logo"
                        />
                      </div>
                    </>
                  ) : null
                  // <>
                  //   <div style={{ width: '500px' }}>
                  //     <img
                  //       style={{
                  //         display: 'block',
                  //         width: '80%',
                  //         height: 'auto',
                  //       }}
                  //       src={irisLogo}
                  //       alt="Logo"
                  //     />
                  //   </div>
                  //   <img
                  //     style={{
                  //       width: '700px',
                  //       height: 'auto',
                  //       // border: '20px solid #FBBC04',
                  //       // borderRadius: '50%',
                  //       padding: '10px',
                  //       // background: '#ffff',
                  //     }}
                  //     src={googleAdManagerLogo2}
                  //     alt="Logo"
                  //   />
                  //   <div className={styles.thirdparty}>3rd party ADS</div>
                  // </>
                }
              </div>
            </div>
          </div>
        </div>
        <NavigationBottomButtons
          homeButtonVisible={false}
          settingsButtonVisible={false}
        />
      </div>
    </>
  );
};

export default QuortexPlaySchedular;
