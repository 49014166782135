import React from 'react';
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const BarChart = ({
  value,
  label,
  seriescolor,
  overageColorSeries,
  labels,
}) => {
  // const [dataset, setDataset] = useState([]);
  const data = {
    labels: labels,
    datasets: [
      {
        backgroundColor: [
          seriescolor,
          seriescolor,
          seriescolor,
          seriescolor,
          seriescolor,
        ],
        data: value,
        barThickness: 75,
        stack: '1',
      },
      {
        backgroundColor: overageColorSeries,
        data: [1, 1, 1, 1, 1],
        barThickness: 75,
        stack: '1',
      },
    ],
  };

  const options = {
    animation: {
      duration: 1,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: label,
        position: 'top',
        color: '#ccccdc',
        font: {
          size: '30',
        },
      },
      tooltip: {
        enabled: false, // Hide the tooltip
      },
    },
    layout: {
      padding: {
        left: 20, // Adjust left padding
        right: 20, // Adjust right padding
        top: 0, // Adjust top padding
        bottom: 40, // Adjust bottom padding
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#ccccdc', // Change the color of X axis text to black
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
        },
        max: 1,
      },
    },
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
