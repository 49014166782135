import React from 'react';
import { Helmet } from 'react-helmet';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';

import styles from './Instructions.module.css';

const Instructions = () => {
  return (
    <>
      <Helmet>
        <title>Deployment Instructions</title>
      </Helmet>
      <div className={styles.container}>
        <h1>Installation Guide <br/>Mac</h1>
        <ol className={styles.list}>
          <li>Download web page&nbsp;
            <a href="https://tradeshow.app.cdn.cloud.synamedia.co.uk/tradeshow-app.zip" className={styles.link}>
              source code
            </a>
            &nbsp;and unzip the content.
          </li>
          <li>Open Terminal and run <code className={styles.code}>sudo ./run-demo-offline-mac.sh</code> as an Administrator<br/>
            The script will install tools: packet manager brew, Chrome browser, player mpv if not already available.<br/>
            The script will also download 10GB of video files for offline usage. Please be patient.
          </li>
          <li>Run command: <code className={styles.code}>npm i</code></li>
          <li>Run command: <code className={styles.code}>npm start</code></li>
          <li>
            Open web page at{' '}
            <a href="http://localhost:3000" className={styles.link}>
              http://localhost:3000
            </a>
          </li>
        </ol>
        <div className={styles.dependencies}>
          <h2>Dependencies</h2>
          <b>Installed during installation if not available</b>
          <ul>
            <li>Packet manager brew</li>
            <li>Chrome browser</li>
            <li>Player mpv</li>
            <li>Node.js</li>
          </ul>
        </div>
        <NavigationBottomButtons homeButtonVisible={false} settingsButtonVisible={true} />
      </div>
    </>
  );
};

export default Instructions;

