import React, { useEffect } from 'react';
import styles from './AISubtitles.module.css';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';
import background from '../StartPage/background.png';
import logo from '../StartPage/logo.png';
import aiMediaLogo from './img/AI-Media-Stacked-Light.svg';
import { useSelector, useDispatch } from 'react-redux';
import { switchVideoFilesSources } from '../../actions/index';

const AISubtitles = () => {
  const dispatch = useDispatch();

  // Dispatch the action to switch video file sources
  useEffect(() => {
    dispatch(switchVideoFilesSources());
  }, [dispatch]);

  // Select the video file locations from Redux state
  const { rugby720p50ori, rugby720p50subs } = useSelector(
    (state) => state.videoFilesLocation
  );

  return (
    <div className={styles.screenCenter}>
      <img className={styles.background} src={background} alt="Background" />
      <div className={styles.logoContainer}>
        <img
          className={`${styles.logo} ${styles.leftLogo}`}
          src={aiMediaLogo}
          alt="Logo"
        />
        <img
          className={`${styles.logo} ${styles.rightLogo}`}
          src={logo}
          alt="Logo"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.title_1}>
              Frictionless Live Subtitle Generation
            </div>
            <div className={styles.subtitle}>
              AI-driven, low-latency subtitles for global live events
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <div className={styles.box}>
            <div className={styles.player}>
              <video
                id="video01"
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                controls
              >
                <source src={rugby720p50ori} type="video/mp4"></source>
              </video>
            </div>
            <div className={styles.bottom}></div>
          </div>
          <div className={styles.box}>
            <div className={styles.player}>
              <video
                id="video02"
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                controls
              >
                <source src={rugby720p50subs} type="video/mp4"></source>
              </video>
            </div>
            <div className={styles.bottom}></div>
          </div>
        </div>
      </div>
      <NavigationBottomButtons
        homeButtonVisible={false}
        settingsButtonVisible={false}
      />
    </div>
  );
};

export default AISubtitles;
