import React, { useEffect } from 'react';
import styles from './ServicesGNOC.module.css';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';
import background from '../StartPage/background.png';
import logo from '../StartPage/logo.png';
import streamLayerLogo from './img/BlankLogo.svg'; // Assuming the correct logo is named aiMediaLogo and located in this path

import { useSelector, useDispatch } from 'react-redux';
import { switchVideoFilesSources } from '../../actions/index';

const ServicesGNOC = () => {
  const dispatch = useDispatch();

  // Dispatch the action to switch video file sources
  useEffect(() => {
    dispatch(switchVideoFilesSources());
  }, [dispatch]);

  // Select the video file locations from Redux state
  const { servicesGNOC_HD_Subs, servicesGNOC_HD_Voice, servicesGNOC_UHD_Subs, servicesGNOC_UHD_Voice } = useSelector((state) => state.videoFilesLocation);

  return (
    <div className={styles.screenCenter}>
      <img className={styles.background} src={background} alt="Background" />
      <div className={styles.logoContainer}>
        <img
          className={`${styles.logo} ${styles.leftLogo}`}
          src={streamLayerLogo}
          alt="Logo"
        />
        <img
          className={`${styles.logo} ${styles.rightLogo}`}
          src={logo}
          alt="Logo"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.title_1}>
              We Take You Live With Reliability & Speed! 
            </div>{' '}
            {/* Updated title */}
            <div className={styles.subtitle}>
              Synamedia Expert and Dedicated Services
            </div>{' '}
            {/* Updated subtitle */}
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.player}>
            <video
              id="video02"
              width="100%"
              height="100%"
              autoPlay
              muted
              loop
              controls
            >
              <source src={servicesGNOC_HD_Subs} type="video/mp4" />
            </video>
          </div>
          <div className={styles.bottom}></div>
        </div>
      </div>
      <NavigationBottomButtons
        homeButtonVisible={false}
        settingsButtonVisible={false}
      />
    </div>
  );
};

export default ServicesGNOC;
