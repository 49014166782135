import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import styles from './FirstPage.module.css';
import background from '../StartPage/background.png';
import firstPageImage from './img/firstpage.svg';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';
import { switchVideoFilesSources } from '../../actions/index';

const FirstPage = () => {
  const dispatch = useDispatch();
  dispatch(switchVideoFilesSources());
  const { synamediaLogo } = useSelector((state) => state.videoFilesLocation);
  const navigate = useNavigate();
  const switchToSecondPage = () => {
    navigate('/quortex-second-page');
  };
  return (
    <div>
      <Helmet>
        <title>QuortexLink</title>
      </Helmet>
      <img className={styles.background} src={background} alt="Logo" />
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <video width="100%" height="100%" autoPlay muted loop>
            <source src={synamediaLogo} type="video/mp4"></source>
          </video>
        </div>
        <div className={styles.logotext}>Connect a bigger audience</div>
        <img
          onClick={switchToSecondPage}
          className={styles.firstPageImage}
          src={firstPageImage}
          alt="logo"
        />
      </div>
      <NavigationBottomButtons homeButtonVisible={false} settingsButtonVisible={false} />
    </div>
  );
};

export default FirstPage;
