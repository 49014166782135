import React, { useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import { DataSet, Timeline } from 'vis-timeline/standalone';
import '../../../node_modules/vis-timeline/styles/vis-timeline-graph2d.css';

import styles from './Schedule.module.css';

const Schedule = ({ clip_duration, break_duration }) => {
  // const CLIP_DURATION = (clip_duration || 3) * 60 * 1000; //minutes
  // const BREAK_DURATION = (break_duration || 2) * 60 * 1000; //minutes
  const mediaPoints = [];

  const fillEventDataSet = (mediaPoints) => {
    const currentTime = moment();
    const startTime = moment(currentTime.format('YYYY-MM-DD'));
    const endTime = moment(currentTime).add(1, 'days').startOf('day');
    let trigger = true;
    let start = startTime.valueOf();
    let i = 0;
    let end;
    while (trigger) {
      //play event 1
      end = start + 60000;
      mediaPoints.push({
        id: i,
        group: 0,
        content: `Rugby: Execter vs. Saracens`,
        start: moment(start),
        end: moment(end),
        className: 'sportEvent',
      });
      //ad event 1
      start = end;
      end = start + 20000;
      i++;
      mediaPoints.push({
        id: i,
        group: 0,
        content: `Ad`,
        start: moment(start),
        end: moment(end),
        className: 'ad',
      });
      //play event 2
      start = end;
      end = start + 60000;
      i++;
      mediaPoints.push({
        id: i,
        group: 0,
        content: `Rugby: Execter vs. Saracens`,
        start: moment(start),
        end: moment(end),
        className: 'sportEvent',
      });
      //ad event 2
      start = end;
      end = start + 20000;
      i++;
      mediaPoints.push({
        id: i,
        group: 0,
        content: `Ad`,
        start: moment(start),
        end: moment(end),
        className: 'ad',
      });
      //play event 3
      start = end;
      end = start + 60000;
      i++;
      mediaPoints.push({
        id: i,
        group: 0,
        content: `Rugby: Execter vs. Saracens`,
        start: moment(start),
        end: moment(end),
        className: 'sportEvent',
      });
      //ad event 3
      start = end;
      end = start + 20000;
      i++;
      mediaPoints.push({
        id: i,
        group: 0,
        content: `Ad`,
        start: moment(start),
        end: moment(end),
        className: 'ad',
      });
      //break event
      start = end;
      end = start + 60000;
      i++;
      mediaPoints.push({
        id: i,
        group: 0,
        content: `Break`,
        start: moment(start),
        end: moment(end),
        className: 'break',
      });
      i++;
      start = end;
      if (end.valueOf() >= endTime.valueOf()) {
        trigger = false;
      }
    }
  };

  const graph = useRef();
  var groups = new DataSet([
    {
      id: 0,
      content: 'Sports</br>Event</br>Schedule',
      value: 0.5,
      className: 'event',
    },
  ]);
  var options = {
    width: '100%',
    editable: false,
    margin: { item: 0 },
    stack: false,
    orientation: 'bottom',
    showMajorLabels: false, //show/hide date
    showMinorLabels: true, //show/hide time
    timeAxis: { scale: 'minute', step: 1 },
    // moveable: false,
    // zoomable: false,
    rollingMode: { follow: true, offset: 0.1 },
    // end:
    // start:
    // min: moment() - 4 * 60 * 1000,
    // max: moment() + 4 * 60 * 1000,
  };

  useEffect(() => {
    var timeline = new Timeline(graph.current);
    window.test = timeline;
    timeline.setOptions(options);
    timeline.setGroups(groups);

    // const interval = setInterval(() => {
    // options.end = moment(moment().valueOf() + 5 * 60 * 1000);
    // options.start = moment(moment().valueOf() - 5 * 60 * 1000);
    // timeline.setOptions(options);
    // }, 10000);

    options.end = moment(moment().valueOf() + 2 * 60 * 1000);
    options.start = moment(moment().valueOf() - 2 * 60 * 1000);
    timeline.setOptions(options);
    //generate events dataset
    fillEventDataSet(mediaPoints);
    // }
    var items = new DataSet(mediaPoints);
    timeline.setItems(items);

    // return () => {
    //   clearInterval(interval);
    // };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <style jsx="true" global="false">
        {`
          .vis-timeline {
            border: 0px solid purple;
            font-family: 'Roboto', sans-serif;
            font-size: 1vh;
            padding-left: 0.5vh;
          }

          .vis-item {
            font-size: 1vh;
            padding-top: 0.5vh;
            align-self: flex-end;
            padding-left: 0.5vh;
          }
          .vis-time-axis {
            margin-left: 10px;
            color: white;
          }
          .vis-text {
            margin-left: 10px;
            color: #ffff;
            font-size: 1.5vh;
            padding-left: 0.5vh;
          }
          .sportEvent {
            background: linear-gradient(
              to right,
              #5c0039,
              #840864
            ); /* Gradient from base color to a darker variant */
            border: 2px solid black;
            border-radius: 10px;
            height: 6.55vh;
            color: white;
          }
          .break {
            background: linear-gradient(
              to right,
              #111111,
              #191b1f
            ); /* Gradient from base color to a darker variant */
            border: 2px solid black;
            border-radius: 10px; /* This adds rounded corners; adjust as necessary */
            height: 6.5vh;
            color: #ffff;
          }
          .ad {
            background: linear-gradient(
              to right,
              #025166,
              #0481a3
            ); /* Gradient from base color to a darker variant */
            border: 2px solid black;
            border-radius: 10px; /* This adds rounded corners; adjust as necessary */
            height: 6.5vh;
            color: white;
          }
          .event {
            background: #0000;
          }
        `}
      </style>
      <div className={styles.graph} ref={graph}></div>
    </>
  );
};

export default Schedule;
