import React from 'react';
import Button from '@mui/material/Button';

import styles from './QuortexPowerVU.module.css';
import Background from '../Background/Background';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';

const QuortexPowerVU = () => {
  const url =
    'https://ordku42ffarppihwzxzxhgez5y0zxwyq.lambda-url.us-east-1.on.aws/';

  const data01 = {
    action: 'power on',
    instances: [{ id: 'i-0baf5588ad0e261c1', region: 'us-east-1' }],
  };

  const data02 = {
    action: 'power on',
    instances: [{ id: 'i-02b1e4ec7699e8e22', region: 'eu-west-1' }],
  };

  return (
    <>
      <Background />
      <div className={styles.header}>Quortex PowerVU</div>
      <div className={styles.section}>
        <div className={styles.element}>
          <header01 className={styles.header01}>MEG 01</header01>
          <div>Localtion: eu-west-1</div>
          <div>
            <div style={{ display: 'inline' }}>Web UI:</div>
            <a
              href="https://meg-01.cloud.synamedia.co.uk"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              https://meg-01.cloud.synamedia.co.uk
            </a>
          </div>
          <div>
            <div style={{ display: 'inline' }}>Grafana:</div>
            <a
              href="https://meg-01.cloud.synamedia.co.uk:3000"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              https://meg-01.cloud.synamedia.co.uk:3000
            </a>
          </div>
          <div>Credentials: Administrator/Synamedia123!</div>

          <Button
            variant="contained"
            size="large"
            onClick={() => {
              try {
                fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data01),
                }).then((response) => {
                  if (!response.ok) {
                    throw new Error(
                      'Network response was not ok' + response.statusText
                    );
                  }
                });
              } catch (error) {
                console.error('Error:', error);
              }
            }}
          >
            <div className={styles.button}>Start Instance</div>
          </Button>
        </div>
        <div className={styles.element}>
          <header01 className={styles.header01}>MEG 02</header01>
          <div>Localtion: us-east-1</div>
          <div>
            <div style={{ display: 'inline' }}>Web UI:</div>
            <a
              href="https://meg-02.cloud.synamedia.co.uk"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              https://meg-02.cloud.synamedia.co.uk
            </a>
          </div>
          <div>
            <div style={{ display: 'inline' }}>Grafana:</div>
            <a
              href="https://meg-02.cloud.synamedia.co.uk:3000"
              target="_blank"
              rel="noreferrer"
              className={styles.link}
            >
              https://meg-02.cloud.synamedia.co.uk:3000
            </a>
          </div>
          <div>Credentials: Administrator/Synamedia123!</div>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              try {
                fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data02),
                }).then((response) => {
                  if (!response.ok) {
                    throw new Error(
                      'Network response was not ok' + response.statusText
                    );
                  }
                });
              } catch (error) {
                console.error('Error:', error);
              }
            }}
          >
            <div className={styles.button}>Start Instance</div>
          </Button>
        </div>
      </div>
      <div className={styles.info}>
        <header01 className={styles.header01}>DASHBOARDS</header01>
        <div>
          <div style={{ display: 'inline' }}>Web UI:</div>
          <a
            href="https://dashboard.c99qhjnr.vncloud-staging.synamedia.com"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            https://dashboard.c99qhjnr.vncloud-staging.synamedia.com
          </a>
        </div>
        <div>user: sro-user1</div>
        <div>password: Sro@user123!</div>
        <div>Quortex PowerVu Operations in folder:currentop</div>
      </div>
      <div className={styles.info}>
        <header01 className={styles.header01}>PNC</header01>
        <div>
          <div style={{ display: 'inline' }}>Web UI:</div>
          <a
            href="https://pnc-a.c99qhjnr.vncloud-staging.synamedia.com"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            https://pnc-a.c99qhjnr.vncloud-staging.synamedia.com
          </a>
        </div>
        <div>user: Administrator</div>
        <div>password: Administrator</div>
      </div>
      <div
        style={{ color: '#bcbcbc', paddingLeft: '5vw', paddingTop: '100px' }}
      >
        * To access the Web UI, you need to be on the Synamedia VPN in
        Chandler's Ford. If the Web UI is not available, contact the CloudOps
        Team.
      </div>
      <div
        style={{ color: '#bcbcbc', paddingLeft: '5vw', paddingTop: '100px' }}
      >
        * MEG instances auto-shutdown 10 hours after start.
      </div>
      <NavigationBottomButtons
        homeButtonVisible={false}
        settingsButtonVisible={false}
      />
    </>
  );
};

export default QuortexPowerVU;
