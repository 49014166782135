import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './RegionalInsertion.module.css';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';
import background from '../StartPage/background.png';
import logo from '../StartPage/logo.png';
import VizRTlogo from './img/Vizrt-Logo-Orange_L.png';
import SimpleTheoPlayer from '../SimpleTheoPlayer/SimpleTheoPlayer';
import { FaSlidersH } from 'react-icons/fa';
import { switchVideoFilesSources } from '../../actions/index';

const createSourceObject = (url) => {
  const hlsUrl = /http.+\.m3u8/;
  const dashUrl = /http.+\.mpd/;
  if (dashUrl.test(url)) {
    return {
      src: dashUrl.exec(url)[0],
      type: 'application/dash+xml',
      lowLatency: true,
      ottType: 'dash',
    };
  } else if (hlsUrl.test(url)) {
    return {
      src: hlsUrl.exec(url)[0],
      type: 'application/x-mpegurl',
      lowLatency: true,
    };
  } else {
    return {
      src: url,
      type: 'application/x-mpegurl',
      lowLatency: false,
    };
  }
};

const RegionalInsertion = () => {
  const dispatch = useDispatch();

  // Dispatch the action to switch video file sources
  useEffect(() => {
    dispatch(switchVideoFilesSources());
  }, [dispatch]);

  // Select the video file locations from Redux state
  const { hockey1Clip, hockey2Clip } = useSelector(
    (state) => state.videoFilesLocation
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState('');
  const [defaultUrl, setDefaultUrl] = useState('');
  const [controlUrl, setControlUrl] = useState('');
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [playerId, setPlayerId] = useState(null);
  const [useVideoTag, setUseVideoTag] = useState(true); // New state for the switch

  const player01Ref = useRef(null);
  const player02Ref = useRef(null);

  const onPlayer01 = useCallback((player) => {
    player.muted = true;
    player.autoplay = true;
    player.source = {
      sources: [
        createSourceObject(
          'http://10.50.234.142:8092/hls/hockey1/HOCKEY1.m3u8'
        ),
      ],
    };
    player01Ref.current = player;
  }, []);

  const onPlayer02 = useCallback((player) => {
    player.muted = true;
    player.autoplay = true;
    player.source = {
      sources: [
        createSourceObject(
          'http://10.50.234.142:8092/hls/hockey2/HOCKEY2.m3u8'
        ),
      ],
    };
    player02Ref.current = player;
  }, []);

  const openModal = (url, controlUrl, playerId, event) => {
    setCurrentUrl(url);
    setDefaultUrl(url);
    setControlUrl(controlUrl);
    setPlayerId(playerId);
    setModalPosition({
      top: event.target.getBoundingClientRect().top - 180,
      left:
        event.target.getBoundingClientRect().left +
        event.target.offsetWidth / 2 -
        200,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const applySettings = () => {
    if (playerId === 1 && player01Ref.current) {
      player01Ref.current.source = {
        sources: [createSourceObject(currentUrl)],
      };
    } else if (playerId === 2 && player02Ref.current) {
      player02Ref.current.source = {
        sources: [createSourceObject(currentUrl)],
      };
    }
    closeModal();
  };

  const resetToDefault = () => {
    setCurrentUrl(defaultUrl);
  };

  return (
    <div className={styles.screenCenter}>
      <img className={styles.background} src={background} alt="Background" />
      <div className={styles.logoContainer}>
        <img
          className={`${styles.logo} ${styles.leftLogo}`}
          src={VizRTlogo}
          alt="Logo"
        />
        <img
          className={`${styles.logo} ${styles.rightLogo}`}
          src={logo}
          alt="Logo"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.title_1}>
              Personalise Content by Region or Affiliate
            </div>
            <div className={styles.subtitle}>
              Edge graphics insertion with MEG / MEG.mini
            </div>
          </div>
        </div>
        <div className={styles.container}>
          {/* First Video Box */}
          <div className={styles.box}>
            <div className={styles.player}>
              {useVideoTag ? (
                <video
                  id="video01"
                  width="100%"
                  height="100%"
                  autoPlay
                  muted
                  controls
                  onTimeUpdate={(e) => {
                    if (e.target.currentTime >= 250) {
                      e.target.currentTime = 0; // Restart the video
                      e.target.play(); // Ensure the video starts playing again
                    }
                  }}
                >
                  <source src={hockey1Clip} type="video/mp4" />
                </video>
              ) : (
                <SimpleTheoPlayer onPlayer={onPlayer01} />
              )}
            </div>
            <div className={styles.bottom}></div>
            <button
              className={styles.adjustButton}
              onClick={(e) =>
                openModal(
                  'http://10.50.234.142:8092/hls/hockey1/HOCKEY1.m3u8',
                  'https://viz.flowics.com/control/21675/viz/66d603dc12d0d9423e22c72d',
                  1,
                  e
                )
              }
            >
              <FaSlidersH />
            </button>
          </div>

          {/* Second Video Box */}
          <div className={styles.box}>
            <div className={styles.player}>
              {useVideoTag ? (
                <video
                  id="video02"
                  width="100%"
                  height="100%"
                  autoPlay
                  muted
                  controls
                  onTimeUpdate={(e) => {
                    if (e.target.currentTime >= 250) {
                      e.target.currentTime = 0; // Restart the video
                      e.target.play(); // Ensure the video starts playing again
                    }
                  }}
                >
                  <source src={hockey2Clip} type="video/mp4" />
                </video>
              ) : (
                <SimpleTheoPlayer onPlayer={onPlayer02} />
              )}
            </div>
            <div className={styles.bottom}></div>
            <button
              className={styles.adjustButton}
              onClick={(e) =>
                openModal(
                  'http://10.50.234.142:8092/hls/hockey2/HOCKEY2.m3u8',
                  'https://viz.flowics.com/control/21675/viz/66d7c6ea07fcf52cd9ac73f2',
                  2,
                  e
                )
              }
            >
              <FaSlidersH />
            </button>
          </div>
        </div>
      </div>

      <NavigationBottomButtons
        homeButtonVisible={false}
        settingsButtonVisible={false}
      />

      {isModalOpen && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div
            className={styles.modalContent}
            style={{ top: modalPosition.top, left: modalPosition.left }}
            onClick={(e) => e.stopPropagation()}
          >
            <button className={styles.closeButton} onClick={closeModal}>
              X
            </button>
            <h7>Edit Video Stream URL</h7>

            <input
              className={styles.urlInput}
              value={currentUrl}
              onChange={(e) => setCurrentUrl(e.target.value)}
            />
            <div className={styles.modalButtons}>
              <button
                className={styles.modalButton}
                onClick={() => window.open(controlUrl, '_blank')}
              >
                Control
              </button>
              <button className={styles.modalButton} onClick={resetToDefault}>
                Default
              </button>
              <button className={styles.modalButton} onClick={closeModal}>
                Cancel
              </button>
              <button className={styles.modalButton} onClick={applySettings}>
                Apply
              </button>
            </div>
            <div className={styles.switchContainer}>
              <label className={styles.switchLabel}>
                Use local mp4 stream (disable requires VPN)
                <input
                  type="checkbox"
                  checked={useVideoTag}
                  onChange={() => setUseVideoTag(!useVideoTag)}
                  className={styles.switchInput}
                />
                <span className={styles.switchSlider}></span>
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegionalInsertion;
