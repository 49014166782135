import ReactDOM from 'react-dom/client';
import {
  Routes,
  Route,
  HashRouter,
  Navigate,
  useLocation,
} from 'react-router-dom';
import React, { lazy, Suspense } from 'react';

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import './index.css';
import reportWebVitals from './reportWebVitals';

import reducer from './reducers';
import Login from './components/Authentication/Login';
import Quortex from './components/Quortex/FirstPage';
import QuortexSecondPage from './components/Quortex/SecondPage';
import QuortexLink from './components/Quortex/QuortexLink';
import QuortexPlay from './components/Quortex/QuortexPlay';
import QuortexPlayAds from './components/Quortex/QuortexPlayAds';
import FrontPage from './components/FrontPage/FrontPage';
import Template from './components/Template/Template';
import PVMAF from './components/pVMAF/pVMAF';
import QuortexPlaySchedular from './components/QuortexPlaySchedular/QuortexPlaySchedular';
import QuortexAD from './components/QuortexAD/QuortexPlaySchedular';
import LinearADInsertion from './components/LinearADInsertion/LinearADInsertion';
import InstructionsWindows from './components/Instructions/InstructionsWindows';
import InstructionsMAC from './components/Instructions/InstructionsMAC';
import Settings from './components/Instructions/Settings'; // Ensure this path is correct
import QuortexPowerVU from './components/QuortexPowerVU/QuortexPowerVU';
import CloudDVR from './components/CloudDVR/CloudDVR';
import SideBySide from './components/SideBySide/SideBySide';
import AISubtitles from './components/AISubtitles/AISubtitles';
import RegionalInsertion from './components/RegionalInsertion/RegionalInsertion';
import ShoppableAds from './components/ShoppableAds/ShoppableAds';
import ServicesGNOC from './components/ServicesGNOC/ServicesGNOC';

// import TwoTheoPlayersSidebySide from './components/TwoTheoPlayersSidebySide/TwoTheoPlayersSidebySide';
// import TwoTheoPlayersSidebySide2 from './components/TwoTheoPlayersSidebySide-2/TwoTheoPlayersSidebySide';

// Authentication
import userpool from './userpool';

const CostSavings = lazy(() => import('./components/CostsSavings/CostSavings'));
const FrameRateConversion = lazy(() =>
  import('./components/FrameRateConversion/FrameRateConversion')
);
const Probe = lazy(() => import('./components/Probe/Probe'));
const ScheduleDemo = lazy(() =>
  import('./components/ScheduleDemo/ScheduleDemo')
);
const ABRLatencyShowcase = lazy(() =>
  import('./components/ABRLatencyShowcase/ABRLatencyShowcase')
);
const ABRZappingShowcase = lazy(() =>
  import('./components/ABRZappingShowcase/ABRZappingShowcase')
);

const CDVR = lazy(() => import('./components/cDVR/cDVR'));

const store = configureStore({
  reducer,
});

const RequireAuth = ({ children }) => {
  const hostName = window.location.hostname;
  const user = userpool.getCurrentUser();
  const location = useLocation();

  if (
    !user &&
    (hostName === '127.0.0.1' ||
      hostName === 'tradeshow.app.cdn.cloud.synamedia.co.uk')
  ) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Provider store={store}>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <FrontPage />
            </RequireAuth>
          }
        />
        <Route
          path="/quortex"
          element={
            <RequireAuth>
              <Quortex />
            </RequireAuth>
          }
        />
        <Route
          path="/powervu"
          element={
            <RequireAuth>
              <QuortexPowerVU />
            </RequireAuth>
          }
        />
        <Route
          path="/quortex-second-page"
          element={
            <RequireAuth>
              <QuortexSecondPage />
            </RequireAuth>
          }
        />
        <Route
          path="/quortexlink"
          element={
            <RequireAuth>
              <QuortexLink />
            </RequireAuth>
          }
        />
        <Route
          path="/quortexplay"
          element={
            <RequireAuth>
              <QuortexPlay />
            </RequireAuth>
          }
        />
        <Route
          path="/CloudDVR"
          element={
            <RequireAuth>
              <CloudDVR />
            </RequireAuth>
          }
        />
        <Route
          path="/template"
          element={
            <RequireAuth>
              <Template />
            </RequireAuth>
          }
        />
        <Route
          path="/pVMAF"
          element={
            <RequireAuth>
              <PVMAF />
            </RequireAuth>
          }
        />
        <Route
          path="/qps"
          element={
            <RequireAuth>
              <QuortexPlaySchedular />
            </RequireAuth>
          }
        />
        <Route
          path="/qpad"
          element={
            <RequireAuth>
              <QuortexAD />
            </RequireAuth>
          }
        />
        <Route
          path="/sidebyside"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <SideBySide />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/costsavings"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <CostSavings />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/frc"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <FrameRateConversion />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/probe"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <Probe />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/inno"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <ScheduleDemo />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/aisubtitles"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <AISubtitles />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/regionalinsertion"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <RegionalInsertion />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route path="/linear-ad-insertion" element={<LinearADInsertion />} />
        <Route
          path="/quortexplayads"
          element={
            <RequireAuth>
              <QuortexPlayAds />
            </RequireAuth>
          }
        />
        <Route
          path="/instructions-windows"
          element={
            <RequireAuth>
              <InstructionsWindows />
            </RequireAuth>
          }
        />
        <Route
          path="instructions-mac"
          element={
            <RequireAuth>
              <InstructionsMAC />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        <Route
          path="/cDVR"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <CDVR />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/ShoppableAds"
          element={
            <RequireAuth>
              <ShoppableAds />
            </RequireAuth>
          }
        />
        <Route
          path="/Services"
          element={
            <RequireAuth>
              <ServicesGNOC />
            </RequireAuth>
          }
        />
        <Route
          path="/abrlatency"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ABRLatencyShowcase />
            </Suspense>
          }
        />
        <Route
          path="/abrzapping"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <ABRZappingShowcase />
            </Suspense>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Provider>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals.console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals\
reportWebVitals();
