import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './NavigationBottomButtons.module.css';
import homeIcon from './img/home.svg';
import bookIcon from './img/book.svg';
import settingsIcon from './img/settings.svg';

const NavigationBottomButtons = ({ homeButtonVisible, settingsButtonVisible }) => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/quortex');
  };
  const navigateToSettings = () => {
    navigate('/settings');
  };
  const navigateToStart = () => {
    navigate('/');
  };

  return (
    <div className={styles.navigation}>
      <div 
        className={styles.navButton} 
        onClick={navigateToSettings}
        style={{ visibility: settingsButtonVisible ? 'visible' : 'hidden' }}
      >
        <img style={{ width: '100%', height: '100%' }} src={settingsIcon} alt="Settings" />
      </div>
      <div 
        className={styles.navButton} 
        onClick={navigateToHome}
        style={{ visibility: homeButtonVisible ? 'visible' : 'hidden' }}
      >
        <img style={{ width: '100%', height: '100%' }} src={homeIcon} alt="Home" />
      </div>
      <div className={styles.navButton} onClick={navigateToStart}>
        <img style={{ width: '100%', height: '100%' }} src={bookIcon} alt="Book" />
      </div>
    </div>
  );
};

export default NavigationBottomButtons;
