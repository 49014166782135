import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
// import { useEpg, Epg, Layout } from 'planby';
import background from '../StartPage/background.png';
// import { v4 as uuidv4 } from 'uuid';
import schedule from 'node-schedule';

import styles from './QuortexPlaySchedular.module.css';
// import ad01 from './images/ad01.png';
import Dashboard from './Dashboard';
// import synamedia from './images/synamedia-mark-white-rgb.svg';
import moment from 'moment-timezone';
import Schedule from './Schedule';
import quortexLogo from './images/Quortex-Logo-Colour-Dark-RGB.svg';
import logo from '../StartPage/logo.png';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';
import highlight01 from './images/highlight01.jpg';
import highlight02 from './images/highlight02.jpg';
import highlight03 from './images/highlight03.jpg';
import highlight04 from './images/highlight04.jpg';
import highlight05 from './images/highlight05.jpg';
import highlight06 from './images/highlight06.jpg';
import {
  d2cstreamingvideo,
  d2cstreamingbreak,
  d2csmallresvideo,
  d2cnoinputvideo,
} from '../../video';

const LATENCY = 5.186;

const QuortexPlaySchedular = () => {
  var videoFile = '';
  var pauseVideoFile = '';
  var ingestVideoStream = '';
  var noIngestVideoStream = '';
  const hostName = window.location.hostname;
  if (
    hostName === '127.0.0.1' ||
    hostName === 'tradeshow.app.cdn.cloud.synamedia.co.uk'
  ) {
    videoFile = d2cstreamingvideo.remote;
    pauseVideoFile = d2cstreamingbreak.remote;
    ingestVideoStream = d2csmallresvideo.remote;
    noIngestVideoStream = d2cnoinputvideo.remote;
  } else {
    videoFile = d2cstreamingvideo.local;
    pauseVideoFile = d2cstreamingbreak.local;
    ingestVideoStream = d2csmallresvideo.local;
    noIngestVideoStream = d2cnoinputvideo.local;
  }
  const ingestVideoPlayer = useRef();
  const ingestVideoSource = useRef();

  const CLIP_DURATION = 4; //minutes
  const BREAK_DURATION = 1; //minutes
  const CYCLEPERIOD = (CLIP_DURATION + BREAK_DURATION) * 60;
  const video = useRef();
  const source = useRef();
  const videoClockRef = useRef();
  const wallclockRef = useRef();
  const eventName = useRef();
  const [highlightVisibility01, setHighlightVisibility01] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility02, setHighlightVisibility02] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility03, setHighlightVisibility03] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility04, setHighlightVisibility04] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility05, setHighlightVisibility05] = useState({
    visibility: 'hidden',
  });
  const [highlightVisibility06, setHighlightVisibility06] = useState({
    visibility: 'hidden',
  });

  const highlights = [
    {
      time: 20,
      setVisibility: setHighlightVisibility01,
      interval: undefined,
    },
    {
      time: 40,
      setVisibility: setHighlightVisibility02,
      interval: undefined,
    },
    {
      time: 80,
      setVisibility: setHighlightVisibility03,
      interval: undefined,
    },
    {
      time: 100,
      setVisibility: setHighlightVisibility04,
      interval: undefined,
    },
    {
      time: 140,
      setVisibility: setHighlightVisibility05,
      interval: undefined,
    },
    {
      time: 160,
      setVisibility: setHighlightVisibility06,
      interval: undefined,
    },
  ];

  // const startSchedularDate = useRef();
  // const stopSchedularDate = useRef();

  window.qpDemoPlayer = video.current;

  // const resizeWindow = () => {
  //   setWindowWidth(window.innerWidth);
  // };

  //track window resize
  // useEffect(() => {
  //   resizeWindow();
  //   window.addEventListener('resize', resizeWindow);
  //   return () => window.removeEventListener('resize', resizeWindow);
  // }, []);

  // const channels = useMemo(
  //   () => [
  //     {
  //       logo: synamedia,
  //       uuid: channelUIID,
  //     },
  //   ],
  //   []
  // );

  // const delay = (ms) => {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, ms);
  //   });
  // };
  var playVideoTimeout01 = undefined;
  const playVideo = () => {
    playVideoTimeout01 = setTimeout(() => {
      source.current.src = pauseVideoFile;

      //remove highlights
      highlights.forEach((e) => {
        e.setVisibility({
          visibility: 'hidden',
        });
      });

      eventName.current.textContent = 'Break';
      video.current.load();
    }, CLIP_DURATION * 60 * 1000);
    //main screen
    source.current.src = videoFile;
    eventName.current.textContent = 'On Air';
    video.current.load();
    //ingest
    // ingestVideoSource.current.src = ingestVideoStream;
    // ingestVideoPlayer.current.currentTime = LATENCY;
    // ingestVideoPlayer.current.load();
  };
  //player
  useEffect(() => {
    var playVideoTimeout02 = undefined;
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const timeSinceMidnight = hours * 60 * 60 + minutes * 60 + seconds;
    const cycleTimeLeft =
      CLIP_DURATION * 60 - (timeSinceMidnight % CYCLEPERIOD);
    if (cycleTimeLeft > 0) {
      source.current.src = videoFile;
      ingestVideoSource.current.src = ingestVideoStream;
      eventName.current.textContent = 'On Air';
      video.current.currentTime = CLIP_DURATION * 60 - cycleTimeLeft;
      ingestVideoPlayer.current.currentTime =
        CLIP_DURATION * 60 - cycleTimeLeft + LATENCY;
      ingestVideoPlayer.current.load();

      playVideoTimeout02 = setTimeout(() => {
        source.current.src = pauseVideoFile;
        //remove highlights
        highlights.forEach((e) => {
          e.setVisibility({
            visibility: 'hidden',
          });
        });
        eventName.current.textContent = 'Break';
        video.current.load();
      }, cycleTimeLeft * 1000);
    } else {
      source.current.src = pauseVideoFile;
      //remove highlights
      highlights.forEach((e) => {
        e.setVisibility({
          visibility: 'hidden',
        });
      });
      video.current.load();
      eventName.current.textContent = 'Break';
      video.current.currentTime = -cycleTimeLeft;
      ingestVideoSource.current.src = noIngestVideoStream;
      ingestVideoPlayer.current.load();
    }
    // eslint-disable-next-line
    const job = schedule.scheduleJob(
      `*/${CLIP_DURATION + BREAK_DURATION} * * * *`,
      playVideo
    );
    return () => {
      if (playVideoTimeout01) {
        clearTimeout(playVideoTimeout01);
      }
      if (playVideoTimeout02) {
        clearTimeout(playVideoTimeout02);
        // if (job) job.gracefulShutdown();
      }
    };
    // eslint-disable-next-line
  }, []);

  //adjust timewindow for schedule
  // useEffect(() => {
  //   const today = new Date();
  //   const epochTime = today.getTime();
  //   const startEpochSchedularDate = epochTime - 30 * 60 * 1000;
  //   const stopEpochSchedularDate = epochTime + 30 * 60 * 1000;

  //   startSchedularDate.current = convertDateToString1(startEpochSchedularDate);
  //   stopSchedularDate.current = convertDateToString1(stopEpochSchedularDate);
  //   setInterval(() => {
  //     const today = new Date();
  //     const epochTime = today.getTime();
  //     const startEpochSchedularDate = epochTime - 30 * 60 * 1000;
  //     const stopEpochSchedularDate = epochTime + 30 * 60 * 1000;

  //     startSchedularDate.current = convertDateToString1(
  //       startEpochSchedularDate
  //     );
  //     stopSchedularDate.current = convertDateToString1(stopEpochSchedularDate);
  //   }, 60000);
  // }, []);
  //update wallclock
  useEffect(() => {
    if (video.current) {
      var wallclockUpdate = setInterval(() => {
        const now = moment();

        if (!video.current.paused && source.current.src !== pauseVideoFile) {
          wallclockRef.current.textContent = `${('00' + now.hour()).slice(
            -2
          )}:${('00' + now.minutes()).slice(-2)}:${('00' + now.seconds()).slice(
            -2
          )}:${('000' + now.millisecond()).slice(-3)}`;

          now.subtract(LATENCY, 'seconds');

          videoClockRef.current.textContent = `${('00' + now.hour()).slice(
            -2
          )}:${('00' + now.minutes()).slice(-2)}:${('00' + now.seconds()).slice(
            -2
          )}:${('000' + now.millisecond()).slice(-3)}`;
        }
        if (source.current.src === pauseVideoFile) {
          wallclockRef.current.textContent = null;
          videoClockRef.current.textContent = null;
        }
      }, 1);
    }

    return () => {
      clearInterval(wallclockUpdate);
    };
    // eslint-disable-next-line
  }, []);

  //highlights
  useEffect(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const timeSinceMidnight = hours * 60 * 60 + minutes * 60 + seconds;
    const cycleTimeLeft =
      CLIP_DURATION * 60 - (timeSinceMidnight % CYCLEPERIOD);
    if (cycleTimeLeft > 0) {
      highlights.forEach((e) => {
        if (e.time < CLIP_DURATION * 60 - cycleTimeLeft) {
          e.setVisibility({
            visibility: 'visible',
          });
        } else {
          e.interval = setTimeout(() => {
            e.setVisibility({
              visibility: 'visible',
            });
          }, (e.time - CLIP_DURATION * 60 + cycleTimeLeft) * 1000);
        }
      });
    }
    const showHighlights = () => {
      highlights.forEach((e) => {
        e.interval = setTimeout(() => {
          e.setVisibility({
            visibility: 'visible',
          });
        }, e.time * 1000);
      });
    };
    // eslint-disable-next-line
    const job = schedule.scheduleJob(
      `*/${CLIP_DURATION + BREAK_DURATION} * * * *`,
      showHighlights
    );
    return () => {
      highlights.forEach((e) => {
        if (e.interval) clearTimeout(e.interval);
      });
      // if (job) job.gracefulShutdown();
    };
    // eslint-disable-next-line
  }, []);

  // const convertDateToString1 = (date) => {
  //   const dateOject = new Date(date);
  //   return `${dateOject.getFullYear()}-${(dateOject.getMonth() + 1)
  //     .toString()
  //     .padStart(2, '0')}-${dateOject
  //     .getDate()
  //     .toString()
  //     .padStart(2, '0')}T${dateOject
  //     .getHours()
  //     .toString()
  //     .padStart(2, '0')}:${dateOject
  //     .getMinutes()
  //     .toString()
  //     .padStart(2, '0')}:${dateOject.getSeconds().toString().padStart(2, '0')}`;
  // };

  // const convertDateToString = (date) => {
  //   const dateOject = new Date(date);
  //   return `${dateOject.getUTCFullYear()}-${(dateOject.getUTCMonth() + 1)
  //     .toString()
  //     .padStart(2, '0')}-${dateOject
  //     .getUTCDate()
  //     .toString()
  //     .padStart(2, '0')}T${dateOject
  //     .getUTCHours()
  //     .toString()
  //     .padStart(2, '0')}:${dateOject
  //     .getUTCMinutes()
  //     .toString()
  //     .padStart(2, '0')}:${dateOject
  //     .getUTCSeconds()
  //     .toString()
  //     .padStart(2, '0')}`;
  // };
  // const populateEpg = (epgData) => {
  //   let condition = true;
  //   const today = new Date();

  //   const startDate = new Date(
  //     `${today.getFullYear()}-${(today.getMonth() + 1)
  //       .toString()
  //       .padStart(2, '0')}-${today
  //       .getDate()
  //       .toString()
  //       .padStart(2, '0')}T00:00:00.000Z`
  //   );
  //   const endtDate = new Date(
  //     `${today.getFullYear()}-${(today.getMonth() + 1)
  //       .toString()
  //       .padStart(2, '0')}-${(today.getDate() + 1)
  //       .toString()
  //       .padStart(2, '0')}T00:00:00.000Z`
  //   );
  //   let time = startDate.getTime();
  //   while (condition) {
  //     epgData.push({
  //       channelUuid: channelUIID,
  //       description: 'This is a racing event',
  //       id: uuidv4(),
  //       image: thumbnail,
  //       since: convertDateToString(time),
  //       till: convertDateToString(time + CLIP_DURATION * 60 * 1000),
  //       title: 'Race',
  //     });
  //     epgData.push({
  //       channelUuid: channelUIID,
  //       description: 'This is a pause',
  //       id: uuidv4(),
  //       image: 'logo',
  //       since: convertDateToString(time + CLIP_DURATION * 60 * 1000),
  //       till: convertDateToString(
  //         time + (BREAK_DURATION + CLIP_DURATION) * 60 * 1000
  //       ),
  //       title: 'Pause',
  //     });
  //     time = time + (BREAK_DURATION + CLIP_DURATION) * 60 * 1000;
  //     if (time > endtDate.getTime()) {
  //       condition = false;
  //     }
  //   }
  // };

  // const epg = useMemo(() => {
  //   let epgData = [];
  //   populateEpg(epgData);

  //   return epgData;
  //   // eslint-disable-next-line
  // }, []);

  // const {
  //   getEpgProps,
  //   getLayoutProps,
  //   // onScrollToNow,
  //   // onScrollLeft,
  //   // onScrollRight,
  // } = useEpg({
  //   epg,
  //   channels,
  //   startDate: startSchedularDate.current,
  //   endDate: stopSchedularDate.current,
  //   width: Math.round(windowWidth * 0.98),
  //   height: 160,
  //   dayWidth: 7200,
  //   isSidebar: true,
  //   isTimeline: true,
  //   isCurrentTime: true,
  //   isLine: true,
  //   liveRefreshTime: 10,
  // });

  // useEffect(() => {
  //   console.log('onclick', advancedDashboardShow.current);
  //   if (advancedDashboardShow.current) {
  //     mainScreen.current = <img src={grafana} alt="GRAFANA" />;
  //   } else mainScreen.current = <></>;
  // }, [advancedDashboardShow.current]);

  return (
    <>
      <Helmet>
        <title>Quortex Play</title>
      </Helmet>

      <div className={styles.screenCenter}>
        <img className={styles.background} src={background} alt="Logo" />
        <div className={styles.title}>
          <img
            style={{ width: '14vw' }}
            src={quortexLogo}
            alt="quortex logo"
          ></img>
          <div className={styles.title_1}>Sports Streaming Cockpit</div>
          <img className={styles.logo} src={logo} alt="Logo" />
        </div>

        <div className={styles.container}>
          <div className={styles.leftSide}>
            <div className={styles.dashboardContainer}>
              {/* <div className={styles.dashboardHeader} ref={eventName}></div> */}
              {/* <iframe
              style={{ width: '100%', height: '100%', zoom: '0.75' }}
              src="https://10.4.11.69:3000/d/WAV-5Yo4k/dataplane-basic?orgId=1&from=1709777405786&to=1709778305786https://localhost:3000/dashboard/snapshot/8MmNUgsKuGD6QzVr5CJIouJ91cmNkHvb"
            ></iframe> */}
              <Dashboard
                clip_duration={CLIP_DURATION}
                break_duration={BREAK_DURATION}
                videoPlayer={ingestVideoPlayer}
                videoSource={ingestVideoSource}
                ingestVideoStream={ingestVideoStream}
                noIngestVideoStream={noIngestVideoStream}
                latency={LATENCY}
              />
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.center}>
              <div className={styles.playerContainer}>
                <div className={styles.playerHeader} ref={eventName}></div>
                <div className={styles.player}>
                  <video
                    className={styles.playerWindow}
                    ref={video}
                    muted
                    autoPlay
                    loop
                    // controls
                  >
                    <source
                      ref={source}
                      src={videoFile}
                      type="video/mp4"
                    ></source>
                  </video>

                  <div className={styles.wallclock} ref={wallclockRef}></div>
                  <div className={styles.videoClock} ref={videoClockRef}></div>
                </div>
                {/* <img className={styles.adImage} src={ad01} alt="Logo" /> */}
                {/* <div className={styles.cockpit}>
                    Synamedia Streaming Cockpit
                  </div> */}
              </div>
              <div className={`${styles.item} ${styles.panel}`}>
                {/* Content for the first row */}Highlights
              </div>
              <div className={styles.highlights}>
                {/* Repeat the following img tag for up to five images */}
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility01}
                  src={highlight01}
                  alt="highlight01"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility02}
                  src={highlight02}
                  alt="highlight02"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility03}
                  src={highlight03}
                  alt="highlight03"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility04}
                  src={highlight04}
                  alt="highlight04"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility05}
                  src={highlight05}
                  alt="highlight05"
                />
                <img
                  className={styles.responsiveImage}
                  style={highlightVisibility06}
                  src={highlight06}
                  alt="highlight06"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '96vw', height: '10vh' }}>
          {/* <Epg {...getEpgProps()}>
                <Layout {...getLayoutProps()} />
              </Epg> */}
          <Schedule
            clip_duration={CLIP_DURATION}
            break_duration={BREAK_DURATION}
          />
        </div>
        <NavigationBottomButtons homeButtonVisible={false} settingsButtonVisible={false} />
      </div>
    </>
  );
};

export default QuortexPlaySchedular;
