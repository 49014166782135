import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Authentication.module.css';
import userpool from '../../userpool';
import logoutIcon from './img/logout-b.png';

const Logout = () => {
  const Navigate = useNavigate();
  const user = userpool.getCurrentUser();
  const logout = () => {
    user.signOut();
    Navigate('/login');
  };

    // Check if the current hostname is localhost
  const isLocalhost = window.location.hostname === 'localhost';

  return (
    <>
      {!isLocalhost && (
        <div className={styles.logoutContainer}>
          <img
            style={{ width: '5vh', height: '5vh' }}
            src={logoutIcon}
            alt="logout"
            onClick={logout}
          />
        </div>
      )}
    </>
  );
};

export default Logout;
