import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import QuortexPlayLink from './QuortexPlayLink';
import quortexplay from './img/quortexplaywhite.png';
import slidePage1 from './quortex-playads-ppt/page1.png';
import slidePage2 from './quortex-playads-ppt/page2.png';
import { switchVideoFilesSources } from '../../actions/index';
import irisLogo from './img/Iris-Logo-Colour-Dark-RGB@2x.png';
import NavigationBottomButtons from '../NavigationBottomButtons/NavigationBottomButtons';

const QuortexPlayAds = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(switchVideoFilesSources());
  }, [dispatch]);
  const { quortexPlayAds } = useSelector((state) => state.videoFilesLocation);
  const slide = [slidePage1, slidePage2];
  return (
    <div>
      <QuortexPlayLink
        title="Quortex Play Ads"
        header="Personalized Ad Insertion"
        sublogo={quortexplay}
        slide={slide}
        video={quortexPlayAds}
        showNavigation={false}
        sublog_01={irisLogo}
        booth=""
      />

      <div>
        <NavigationBottomButtons
          homeButtonVisible={true}
          settingsButtonVisible={true}
        />
      </div>
    </div>
  );
};

export default QuortexPlayAds;
